import React from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import utils from '../js/utils';
import por from '../js/por';

export default class PoR extends React.Component {
  constructor(){
    super();

    this.state = {
      orders: [],
      isFetchingOrders: true
    };

    this.fetchOrders = this.fetchOrders.bind(this);
    this.handleOrderUpdate = this.handleOrderUpdate.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
  }
  componentDidMount() {
    this.fetchOrders();
  }
  fetchOrders() {
    var self = this;
    var tokenizedStr = utils.genTokenizedString()
    if (tokenizedStr) {
      this.setState({
        isFetchingOrders: true
      })
      por.fetchOrders(tokenizedStr).then(function(res) {
        if (res.status == 200) {
          self.setState({
            orders: res.data.data,
            isFetchingOrders: false
          });
        }
      })
    }
  }
  handleOrderUpdate(orderId, isApproved, index) {
    var self = this;
    var message = isApproved ? '<p>Are you sure you want to approve this order?</p>' : '<p>Are you sure you want to disapprove this order?</p>';
    var label = isApproved ? 'Approve' : 'Disapprove';
    this.props.showModal('Please Confirm', message, () => {self.updateOrder(orderId, isApproved, index)}, label, 'Cancel');
  }
  updateOrder(orderId, isApproved, index) {
    let self = this;
    let tokenizedStr = utils.genTokenizedString();
    if (tokenizedStr) {
      let  payload = {
        por_approved: isApproved
      }

      por.updateOrder(tokenizedStr, orderId, payload).then(function(res) {
        if (res.status == 200) {
          let orders = [...self.state.orders];
          orders.splice(index, 1);
          self.setState({
            orders: orders
          })
        } else {
          if (res.status !== 20) {
            self.props.showModal('An Error occurred', '<p>' + res.statusText + '</p>', undefined, 'OK', 'Close');
          }
        }
      })
    }
  }
  render(){
    return (
      <div>
        <section className="alabaster-bg green-text fullscreen dashboard-hero">
          <div className="wrap intro-text">
            <h2 className="widont balance-text">Physician of Record Order Review</h2>
            <p>Please either approve or disapprove the following orders</p>
          </div>
        </section>
        <section className="marine-bg green-text fullscreen order-hero">
          <div className="wrap intro-text">

            <div>
              <div className="label">
                <p>Orders</p>
              </div>

              {this.state.orders.length > 0 ?
                this.state.orders.map((o, i) => {
                  return (
                    <div key={i} className="split order container">
                      <div className="split-text">
                        <h4><b>Date order placed:</b> {o.date_order_placed}</h4>
                      </div>
                      <div className="split-text">
                        <ul>
                          <li><b>ID:</b> {o.id}</li>
                          <li><b>Status:</b> {o.status}</li>
                          <li><b>Phone:</b> {o.phone}</li>
                          <li><b>Sex at birth:</b> {o.sex}</li>
                          <li><b>Gender:</b> {o.gender}</li>
                          <li><b>Date of birth:</b> {o.date_of_birth}</li>
                        </ul>
                        <fieldset className="button-wrap">
                          <input type="button" className="button" className='button' value="Approve" onClick={() => {this.handleOrderUpdate(o.id, true, i)}}/>&nbsp;
                          <input type="button" className="button" className='button' value="Disapprove" onClick={() => {this.handleOrderUpdate(o.id, false, i)}}/>&nbsp;
                        </fieldset>
                      </div>
                    </div>
                  )
                })
              :
                <p className="p-2">No orders to review.</p>
              }
            </div>

          </div>
        </section>
      </div>
    )
  }
}

PoR.propTypes = {
  user: PropTypes.object,
  isPor: PropTypes.bool,
  showModal: PropTypes.func
}
