import {STRIPE_PATH} from './paths';
import utils from './utils';
import axios from 'axios';

export default {
  name: 'billing',
  fetchStripePrices() {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/price',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      if (res.status == 200) {
        res.data.data = utils.formatUnderscoredFields(res.data.data)
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  fetchStripeCustomer(authStr) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/customer',
      method: 'GET',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      if (res.status == 200) {
        res.data.data = utils.formatUnderscoredFields(res.data.data)
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  createCustomer(authStr, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/customer',
      method: 'POST',
      data: payload,
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  deleteCustomer(authStr, customerId) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/customer/' + customerId,
      method: 'DELETE',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  attachBillingMethod(authStr, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/billing_method',
      method: 'PUT',
      data: payload,
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  deleteStripeBillingMethod(authStr, billingMethodId) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/billing_method/' + billingMethodId,
      method: 'DELETE',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  guestPurchase(priceId, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/price/' + priceId + '/guest',
      method: 'POST',
      withCredentials: true,
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  zeroPurchase(priceId, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/price/' + priceId + '/zero',
      method: 'POST',
      withCredentials: true,
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  purchase(authStr, priceId, kind) {
    utils.handleOutgoingRequest(this);
    let payload = {'kind': kind}
    return axios({
      url: STRIPE_PATH + '/price/' + priceId,
      method: 'POST',
      data: payload,
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  abort() {
    if (this.active) {
      this.cts.cancel();
      this.cts = axios.CancelToken.source() ;
    }
  },
  active: false,
  cts: axios.CancelToken.source()
}
