import React from 'react';
import PropTypes from 'prop-types';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import account from '../js/account';
import shop from '../js/shop';
import utils from '../js/utils';

export default class StripeBillingForm extends React.Component {
  constructor(props){
    super(props);
    var self = this;

    this.state = {
      cardNumber: '',
      cardExpiration: '',
      cardCVV: '',
      cardType: '',
      isCardNumberValid: true,
      isCardExpirationMonthValid: true,
      isCardExpirationYearValid: true,
      isCardCVVValid: true,
      cvvMaxLength: 3,
      isUseShipping: true,
      billingFirstName: '',
      billingLastName: '',
      billingAddress1: '',
      billingAddress2: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
      billingCountry: 'US',
      billingAlert: '',
      zipAlert: '',
      percentOff: 0,
      timesRedeemed: 0,
      couponCode: ''
    }

    this.onUseShippingChange = this.onUseShippingChange.bind(this);
    this.onBillingFirstNameChange = this.onBillingFirstNameChange.bind(this);
    this.onBillingLastNameChange = this.onBillingLastNameChange.bind(this);
    this.onBillingAddress1Change = this.onBillingAddress1Change.bind(this);
    this.onBillingAddress2Change = this.onBillingAddress2Change.bind(this);
    this.onBillingCityChange = this.onBillingCityChange.bind(this);
    this.onBillingStateChange = this.onBillingStateChange.bind(this);
    this.onBillingCountryChange = this.onBillingCountryChange.bind(this);
    this.onBillingZipChange = this.onBillingZipChange.bind(this);
    this.onCouponCodeChange = this.onCouponCodeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.createStripeToken = this.createStripeToken.bind(this);
    this.isBillingStateValid = this.isBillingStateValid.bind(this);
  }
  onUseShippingChange(evt) {
    this.setState({isUseShipping: !this.state.isUseShipping})
  }
  onBillingFirstNameChange(evt) {
    this.setState({billingFirstName: evt.target.value})
  }
  onBillingLastNameChange(evt) {
    this.setState({billingLastName: evt.target.value})
  }
  onBillingAddress1Change(evt) {
    this.setState({billingAddress1: evt.target.value})
  }
  onBillingAddress2Change(evt) {
    this.setState({billingAddress2: evt.target.value})
  }
  onBillingCityChange(evt) {
    this.setState({billingCity: evt.target.value})
  }
  onBillingStateChange(evt) {
    var self = this;
    this.setState({
      billingState: evt.target.value
    }, () => {
      if (!self.isBillingStateValid()) {
        account.updateGuest(self.props.guestId, {
          residence_confirmed: false
        }).then(function(res) {
          if (res.status === 200) {
            console.log('Success: ' + res.statusText)
          } else {
            if (res.status != 20) {
              console.log('Error: ' + res.statusText)
            }
          }
        })
      }
    })
  }
  onBillingCountryChange(evt) {
    this.setState({billingCountry: evt.target.value})
  }
  onBillingZipChange(evt) {
    var zip = utils.validateZip(evt.target.value)
    var newState = {
      billingZip: zip
    }
    if (!utils.isZipValid(zip)) {
      newState['zipAlert'] = 'Invalid zip'
    } else {
      newState['zipAlert'] = '';
    }
    this.setState(newState);
  }
  onCouponCodeChange(evt) {
    this.setState({couponCode: evt.target.value})
  }
  createStripeToken() {
    var self = this;
    var address1 = this.state.isUseShipping ? this.props.address1 : this.state.billingAddress1;
    var address2 = this.state.isUseShipping ? this.props.address2 : this.state.billingAddress2;
    var city = this.state.isUseShipping ? this.props.city : this.state.billingCity;
    var state = this.state.isUseShipping ? this.props.state : this.state.billingState;
    var zip = this.state.isUseShipping ? this.props.zip : this.state.billingZip;
    var country = this.state.isUseShipping ? this.props.country : this.state.billingCountry;

    if (address1 !== '' && city !== '' && state !== '' && zip !== '' && country!== '' && this.state.percentOff !== 100) {
      this.props.stripe.createToken(this.props.elements.getElement(CardElement), {
        type: 'card',
        data: {
          name: this.state.shippingFirstName + ' ' + this.state.shippingLastName,
          address_line1: address1,
          address_line2: address2,
          address_city: city,
          address_state: state,
          address_country: country,
          address_zip: zip
        }
      }).then(function(res) {
        if (res.error) {
          self.setState({
            billingAlert: res.error.message
          });
        }

        if (res.token) {
          self.setState({
            billingAlert: 'Success'
          });
          self.props.onSubmit(res.token.id);
        }
      });
    } else {
      self.props.onSubmit();
    }
  }
  onSubmit(evt) {
    evt.preventDefault();
    var self = this;
    if (this.state.couponCode) {
      shop.fetchCoupon(this.state.couponCode).then(function(res) {
        if (res.status == 200) {
          var percentOff = res.data.data.percentOff;
          self.setState({
            percentOff: percentOff,
            timesRedeemed: res.data.data.timesRedeemed
          })
          self.props.onCouponFetched(self.state.couponCode, percentOff);
          self.createStripeToken();
        } else {
          if (res.status !== 20) {
            self.setState({
              billingAlert: res.statusText
            })
          }
        }
      });
    } else {
      this.createStripeToken();
    }
  }
  isBillingStateValid() {
    return this.state.billingState !== 'NY' && this.state.billingState !== 'NJ' && this.state.billingState !== 'RI';
  }
  render(){
    return (
      <article>
        <form id="guest-checkout-billing-form" onSubmit={this.onSubmit}>
          <article className="tab-content">
            <div className="split">
              <div className="split-text">
                <h4>Payment Method</h4>
                {this.props.disabled && <p className="warning">Please complete the previous sections with valid entries prior to filling out this form.</p>}
              </div>
              <div className="split-text w-100">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </article>
          <article className="tab-content">
            <div className="split">
              <div className="split-text">
                <label className="input-wrap">
                  <h4>Billing Address</h4>
                  <input type="radio" name="use-shipping" id="checkout-use-shipping"
                    value={this.state.isUseShipping}
                    checked={this.state.isUseShipping} 
                    onChange={this.onUseShippingChange}
                  /> Use same as shipping
                </label>
                <label className="input-wrap">
                  <input type="radio" name="new-billing" id="checkout-new-billing"
                    value={this.state.isUseShipping}
                    checked={!this.state.isUseShipping} 
                    onChange={this.onUseShippingChange}
                  /> Enter a new address
                </label>
              </div>

              <div className="split-text">
                {this.state.isUseShipping ?
                  <div>
                    {!this.props.disabled &&
                      <div className="address-block offwhite-bg">
                        <p>{this.props.firstName + ' ' + this.props.lastName}</p>
                        <p>{this.props.address1}</p>
                        {this.props.address2 && <p>{this.props.address2}</p>}
                        <p>{this.props.city + ', ' + this.props.state + ' ' + this.props.zip}</p>
                        <p>{this.props.country}</p>
                      </div>
                    }
                  </div>
                :
                  <div>
                    <div className="split">
                      <div className="split-text">
                        <label className="input-wrap">
                          <input type="text" name="billing-first-name" id="checkout-billing-first-name" className={this.state.billingFirstName !== '' ? 'has-value' : ''} value={this.state.billingFirstName} onChange={this.onBillingFirstNameChange} required disabled={this.props.disabled} />
                          <span>First name</span>
                        </label>
                      </div>
                      <div className="split-text">
                        <label className="input-wrap">
                          <input type="text" name="billing-last-name" id="checkout-billing-last-name" className={this.state.billingLastName !== '' ? 'has-value' : ''} value={this.state.billingLastName} onChange={this.onBillingLastNameChange} required disabled={this.props.disabled} />
                          <span>Last name</span>
                        </label>
                      </div>
                    </div>

                    <label className="input-wrap">
                      <input type="text" name="billing-address1" id="checkout-billing-address1" className={this.state.billingAddress1 !== '' ? 'has-value' : ''} value={this.state.billingAddress1} onChange={this.onBillingAddress1Change} required disabled={this.props.disabled} />
                      <span>Address1</span>
                    </label>

                    <label className="input-wrap">
                      <input type="text" name="billing-address2" id="checkout-billing-address2" className={this.state.billingAddress2 !== '' ? 'has-value' : ''} value={this.state.billingAddress2} onChange={this.onBillingAddress2Change} disabled={this.props.disabled} />
                      <span>Address2</span>
                    </label>

                    <div className="split">
                      <div className="split-text">
                        <label className="input-wrap">
                          <input type="text" name="billing-city" id="checkout-billing-city" className={this.state.billingCity !== '' ? 'has-value' : ''} value={this.state.billingCity} onChange={this.onBillingCityChange} required disabled={this.props.disabled} />
                          <span>City</span>
                        </label>
                      </div>
                      <div className="split-text">
                        <label className="input-wrap">
                          <select id="billing-state" className={this.state.billingState !== '' ? 'has-value' : ''} value={this.state.billingState} onChange={this.onBillingStateChange} disabled={this.props.disabled} >
                            <option value=""></option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NM">New Mexico</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                          <span>State</span>
                        {!this.isBillingStateValid() && <p className="warning">We currently cannot ship to NY, NJ, or RI. Visit our <a href="https://www.adyn.com/faq" target="_blank">FAQ</a> to find out why. When we begin expanding our supported states, we'll reach out to you by email.</p>}
                        </label>
                      </div>
                    </div>

                    <div className="split">
                      <div className="split-text">
                        <label className="input-wrap">
                          <input type="text" name="billing-zip" id="checkout-billing-zip" className={this.state.billingZip !== '' ? 'has-value' : ''} value={this.state.billingZip} onChange={this.onBillingZipChange} required disabled={this.props.disabled} />
                          <span>Zip code</span>
                        </label>
                      </div>
                      <div className="split-text">
                        <label className="input-wrap">
                          <select id="billing-country" className={this.state.billingCountry !== '' ? 'has-value' : ''} value={this.state.billingCountry} onChange={this.onBillingCountryChange} disabled={this.props.disabled} >
                            <option value="US">United States</option>
                          </select>
                          <span>Country</span>
                        </label>
                      </div>
                    </div>

                    <fieldset className="align-center w-100">
                      {this.state.zipAlert !== '' && <div className="alert info">{this.state.zipAlert}</div>}
                    </fieldset>

                  </div>
                }
              </div>
            </div>
          </article>

          <article className="tab-content">
            <div className="split">
              <div className="split-text">
                <h4>Coupon</h4>
              </div>
              <div className="split-text">
                <label className="input-wrap">
                  <input type="text" name="coupon_code" id="checkout-coupon-code" className={this.state.couponCode !== '' ? 'has-value' : ''} value={this.state.couponCode} onChange={this.onCouponCodeChange} />
                  <span>COUPON CODE</span>
                </label>
              </div>
            </div>
          </article>

          <article className="tab-content">
            <div className="split">
              <div className="split-text">
              </div>
              <div className="split-text">
                <fieldset>
                  {this.state.billingAlert !== '' && <div className="alert info">{this.state.billingAlert}</div>}
                </fieldset>

                <fieldset className="button-wrap">
                  <input type="submit" className="button" value="Review Your Order" disabled={this.props.disabled}/>
                </fieldset>
              </div>
            </div>
          </article>
        </form>
      </article>
    )
  }
}

StripeBillingForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  guestId: PropTypes.string,
  onCouponFetched: PropTypes.func
}
