import {POR_PATH} from './paths';
import utils from './utils';
import axios from 'axios';

export default {
  name: 'por',
  fetchOrders(authStr) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: POR_PATH + '/order',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      if (res.status == 200) {
        res.data.data = utils.formatUnderscoredFields(res.data.data)
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  updateOrder(authStr, orderId, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: POR_PATH + '/order/' + orderId,
      method: 'PUT',
      credentials: 'include',
      data: payload,
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  abort() {
    if (this.active) {
      this.cts.cancel();
      this.cts = axios.CancelToken.source() ;
    }
  },
  active: false,
  cts: axios.CancelToken.source()
}

