import {STRIPE_PATH} from './paths';
import utils from './utils';
import axios from 'axios';

export default {
  name: 'shop',
  fetchCoupon(couponId) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/coupon/' + couponId,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      if (res.status == 200) {
        res.data.data = utils.formatUnderscoredFields(res.data.data)
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  fetchStripePrices() {
    utils.handleOutgoingRequest(this);
    return axios({
      url: STRIPE_PATH + '/price',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      if (res.status == 200) {
        res.data.data = utils.formatUnderscoredFields(res.data.data)
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  abort() {
    if (this.active) {
      this.cts.cancel();
      this.cts = axios.CancelToken.source() ;
    }
  },
  active: false,
  cts: axios.CancelToken.source()
}
