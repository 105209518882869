import {MAILER_PATH} from './paths';
import utils from './utils';
import axios from 'axios';

export default {
  name: 'mailer',
  // User
  sendEmail(payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: MAILER_PATH,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  sendReceipt(payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: MAILER_PATH + '/receipt',
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  abort() {
    if (this.active) {
      this.cts.cancel();
      this.cts = axios.CancelToken.source() ;
    }
  },
  active: false,
  cts: axios.CancelToken.source()
}
