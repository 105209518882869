import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

export default class Modal extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="modal-close" onClick={this.props.hideModal}>&times;</span>
          <div className="modal-header"><h4>{this.props.title}</h4></div>
          {ReactHtmlParser(this.props.message)}
          <div className="modal-buttons">
            <button className="button" onClick={() => {this.props.hideModal(); this.props.onConfirm()}}>{this.props.confirmMessage || 'Confirm'}</button> {this.props.cancelMessage && <button className="button" onClick={this.props.hideModal}>{this.props.cancelMessage}</button>}
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmMessage: PropTypes.string,
  cancelMessage: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
}
