import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import JotformEmbed from 'react-jotform-embed';
import jotform from '../js/jotform';
import utils from '../js/utils';
import { MEDICAL_BIOS, MEDICAL_BIO_PARTS, MEDICAL_BIO_DURATIONS } from '../js/constants';

export default class CompleteBio extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      guestId: utils.getCookie('guestId'),
      guestToken: utils.genTokenizedString('guest'),
      jotformFormId: '',
      parameters: {},
      isFetchingForm: true
    }

    this.fetchCurrentForm = this.fetchCurrentForm.bind(this);
  }
  fetchCurrentForm() {
    let self = this
    let guestId = utils.getCookie('guestId')
    let tokenizedStr = utils.genTokenizedString('guest')
    if (tokenizedStr) {
      this.setState({
        isFetchingForm: true
      })
      jotform.fetchCurrentForm(tokenizedStr).then(function(res) {
        if (res.status == 200) {
          let jotformFormId = res.data.data
          self.setState({
            jotformFormId: jotformFormId,
            isFetchingForm: false
          })

          if (jotformFormId === MEDICAL_BIOS.TWO) {
            jotform.fetchSubmission(tokenizedStr, MEDICAL_BIOS.ONE).then(function(res2) {
              if (res2.status == 200) {
                let submission = res2.data.data;
                self.setState({
                  parameters: JSON.parse(submission.parameters),
                  isFetchingForm: false
                })
              } else {
                console.log('error')
              }
            })
          }
        }
      })
    }
  }
  componentDidMount() {
    var self = this;

    window.setTimeout(function() {
      self.fetchCurrentForm();
    }, 3000)
  }
  render(){
    return (
      <div>
        <section className={this.state.guestId && this.state.guestToken ? 'seafoam-bg green-text checkout-hero': 'seafoam-bg green-text fullscreen checkout-hero'}>
          <div className="wrap green-text">
            <h2 className="widont balance-text">Your Medical Biography</h2>
            {this.state.guestId && this.state.guestToken ?
              <div>
                {this.state.jotformFormId && <div>
                  <h3>Part {MEDICAL_BIO_PARTS[this.state.jotformFormId]} of 4</h3>
                  <p className="widont">This section normally takes around {MEDICAL_BIO_DURATIONS[this.state.jotformFormId]} minutes.</p>
                </div>}
              </div>
            :
              <p className="widont">Your session has expired. Please <Link to="/login">login</Link> to complete your medical bio. If you don't have an account, please proceed to the <Link to="/signup">signup</Link> page.</p>
            }
          </div>
        </section>
        {this.state.guestId && this.state.guestToken &&
          <section className="seafoam-bg green-text fullscreen dashboard-hero">
            <div className="wrap intro-text">
              {this.state.jotformFormId === MEDICAL_BIOS.TWO ?
                <div>
                  {this.state.parameters && !this.state.isFetchingForm ?
                    <JotformEmbed src={"https://form.jotform.com/" + this.state.jotformFormId + "?customerId=" + this.state.guestId + "&pill=" + this.state.parameters.pastpill + "&patch=" + this.state.parameters.pastpatch + "&ring=" + this.state.parameters.pastring + "&shot=" + this.state.parameters.pastshot + "&implant=" + this.state.parameters.pastimplant + "&iud=" + this.state.parameters.pastiud + "&ciud=" + this.state.parameters.pastciud + "&nojump&isGuest=true"}/>
                  :
                    <p>Loading form...</p>
                  }
                </div>
              :
                <div>
                  {this.state.isFetchingForm ?
                    <p>Loading form...</p>
                  :
                    <JotformEmbed src={"https://form.jotform.com/" + this.state.jotformFormId + "?customerId=" + this.state.guestId + "&nojump&isGuest=true"} />
                  }
                </div>
              }
            </div>
          </section>
        }
      </div>
    )
  }
}
