import React from 'react';

export default class FourZeroFour extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <section className="marine-bg green-text fullscreen align-center">
        <div className="wrap intro-text">
          <div>
            <h1>404</h1>
            <p>Page not found</p>
          </div>
        </div>
      </section>
    )
  }
}

