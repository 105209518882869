export const RECIPIENT = {
  'account': 'csaccount-lambda',
  'mailer': 'csmailer-worker',
  'auth': 'csauth-lambda'
}

export const MEDICAL_BIOS = {
  'ONE': '212286385804157',
  'TWO': '212286943466161',
  'THREE': '212236634316147',
  'FOUR': '212236136908151',
}

export const MEDICAL_BIO_PARTS = {
  '212286385804157': '1',
  '212286943466161': '2',
  '212236634316147': '3',
  '212236136908151': '4'
}

export const MEDICAL_BIO_DURATIONS = {
  '212286385804157': '3',
  '212286943466161': '5',
  '212236634316147': '2',
  '212236136908151': '2'
}
