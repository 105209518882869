const API_URL = ADYN_API_URL;

export const USER_PATH = API_URL + "/customer"
export const TOKEN_PATH = API_URL + "/token"
export const MAILER_PATH = API_URL + "/email"
export const ADDRESS_PATH = API_URL + "/address"
export const STRIPE_PATH = API_URL + "/stripe"
export const ORDER_PATH = API_URL + "/order"
export const POR_PATH = API_URL + "/por"
export const SHIPMENT_PATH = API_URL + "/shipment"
export const MEDICAL_BIO_PATH = API_URL + "/medicalbio"
export const JOTFORM_PATH = API_URL + "/jotform"
