import axios from 'axios';

export default {
  genTokenizedString(label){
    let token = this.getCookie(label ? label : 'token')
    if (token) {
      return 'Token ' + token
    }
    return null;
  },
  genAuthenticatedString(email, password){
    return 'Basic ' + new Buffer(email + ':' + password).toString('base64')
  },
  validateEmail(email){
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  validatePassword(password){
    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40}$/;
    return re.test(password);
  },
  validatePhoneNumber(input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g,'');
    
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0,10);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = '('+input;
    } else if (size < 7) {
      input = '('+input.substring(0,3)+') '+input.substring(3,6);
    } else {
      input = '('+input.substring(0,3)+') '+input.substring(3,6)+' - '+input.substring(6,10);
    }

    return input;
  },
  validateZip(input) {
    input = input.replace(/\D/g,'');
    input = input.substring(0,9);

    var size = input.length;
    if (size < 6) {
      input = input;
    } else {
      input = input.substring(0,5) + '-' + input.substring(5,9);
    }

    return input;
  },
  isDateOfBirthValid(input) {
    var bday = new Date(input);
    var todayDate = new Date();
    var timeDiff = Math.abs(todayDate.getTime() - bday.getTime());
    var diffYears = Math.ceil(timeDiff / (1000 * 3600 * 24)/365); 
    return diffYears > 18;
  },
  isZipValid(input) {
    var len = input.toString().length;
    return !(len < 5 || (len > 5 && len < 10));
  },
  replaceNullString(str) {
    return (str !== undefined && str !== null) ? str : '';
  },
  replaceNullPhone(str) {
    return (str !== undefined && str !== null && str !== '(555) 555 - 5555') ? str : '';
  },
  replaceNullUndefined(str) {
    return (str !== undefined && str !== null) ? str : undefined;
  },
  isEmptyObject(obj){
    if (Object.entries(obj).length === 0 && obj.constructor === Object) {
      return true;
    }
    return false;
  },
  updateObjWithObj(oldObj, newObj){
    let clone = Object.assign({}, oldObj);
    Object.keys(newObj).forEach(function(key) {
      clone[key] = newObj[key];
    })
    return clone;
  },
  removeArrayItemById(arr, id) {
    for (var i = arr.length - 1; i >= 0; --i) {
      if (arr[i].id == id) {
        arr.splice(i,1);
      }
    }
  },
  getParameterByName(name, url){
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  formatUnderscoredFields(data){
    let keys = Object.keys(data)
    for (let i = 0, len = keys.length; i < len; i++) {
      let key = keys[i];
      let index = key.indexOf('_');
      if (index === -1) {
        continue;
      } else {
        let newKey = key.replace(/([-_][a-z])/ig, ($1) => {
          return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
        });
        Object.defineProperty(data, newKey,
          Object.getOwnPropertyDescriptor(data, key));
        delete data[key];
      }
    }
    return data;
  },
  setCookie(name,value,days){
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/; domain=adyn.com";
  },
  getCookie(name){
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },
  deleteCookie(name){   
    document.cookie = name + "=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=adyn.com;";
  },
  handleOutgoingRequest(instance) {
    instance.abort();
    instance.active = true;
  },
  handleResponse(instance, res) {
    instance.active = false;
    if (res.data && res.data.body) {
      res.statusText = res.data.body;
    }
    return res;
  },
  handleRequestError(instance, err) {
    instance.isActive = false;
    if(axios.isCancel(err)) {
      return { status: 20, statusText: 'Aborted' };
    }

    let res = err.response;
    if (res === undefined) {
      return {
        status: 502,
        statusText: 'Server down for maintenance'
      };
    } else {
      if (res.data && res.data.body) {
        res.statusText = res.data.body;
      }
    }
    switch(res.status){
      case 502:
        break;
      case 405:
        res.statusText = 'Method not allowed';
        break;
      case 401:
        if (res.statusText == 'Token expired' || res.statusText == 'Authorization failed') {
          this.deleteCookie('token');
        }
        break;
    }
    return res;
  },
  uuidv4() {
    if (crypto) {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    } else {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  },
  trackGAEvent(type, data){
    var payload = {};
    if (data !== undefined) {
      Object.assign(payload, data)
    }
    if (window.dataLayer) {
      switch (type) {
        case 'anon':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': true,
              'event': "pageView"
            })
          )
          break;
        case 'consentGiven':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "cookieConsentGiven"
            })
          )
          break;
        case 'optedOut':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "optedOut"
            })
          )
          break;
        case 'pageView':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "pageView"
            })
          )
          break;
        case 'couponRedemption':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "couponRedemption"
            })
          )
          break;
        case 'emailReceipt':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "emailReceipt"
            })
          )
          break;
        case 'guestCreation':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "guestCreation"
            })
          )
          break;
        case 'guestAccountCreation':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "guestAccountCreation"
            })
          )
          break;
        case 'invalidResidence':
          window.dataLayer.push(
            Object.assign(payload, {
              'anonymizeIp': false,
              'event': "invalidResidence"
            })
          )
          break;
      }
    } else {
      console.log('window.dataLayer does not exist. Did you instantiate GA?');
    }
  },
  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
}
