import {USER_PATH} from './paths';
import utils from './utils';
import axios from 'axios';

export default {
  name: 'account',
  fetchUser(authStr, id) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/' + id,
      method: 'GET',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      if (res.status == 200) {
        res.data.data = utils.formatUnderscoredFields(res.data.data)
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  fetchUsers(authStr, queryString) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + (queryString ? '?' : '') + queryString,
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .then(res => { 
      let users = res.data.data.users;
      for (var i = 0, len = users.length; i < len; i++) {
        res.data.data.users[i] = utils.formatUnderscoredFields(users[i]);
      }
      return res;
    })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  createGuest(payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/guest',
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  createUser(payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  updateUser(authStr, id, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/' + id,
      method: 'PUT',
      data: payload,
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  updateGuest(id, payload, authStr) {
    utils.handleOutgoingRequest(this);
    var headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    if (authStr) {
      headers['Authorization'] = authStr;
    }
    return axios({
      url: USER_PATH + '/' + id + '/guest',
      method: 'PUT',
      data: payload,
      headers: headers,
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  // Verification
  verifyUser(payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/verify',
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  reVerifyUser(authStr, userId, payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/resend_update_verification/' + userId,
      method: 'POST',
      data: payload,
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  requestPasswordReset(email) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/request_reset_password',
      method: 'POST',
      data: {email: email},
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  resetPassword(payload) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/reset_password',
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  archiveUser(authStr, id) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/' + id,
      method: 'DELETE',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  deleteUser(authStr, id) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: USER_PATH + '/delete/' + id,
      method: 'DELETE',
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  checkReferralCode(authStr, referral_code) {
    if (referral_code !== '') {
      utils.handleOutgoingRequest(this);
      return axios({
        url: USER_PATH + '/referrer/' + referral_code,
        method: 'GET',
        headers: {
          'Authorization': authStr,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        cancelToken: this.cts.token
      }).then(res => { return utils.handleResponse(this, res) })
      .then(res => { 
        return res;
      })
      .catch(err => { return utils.handleRequestError(this, err)})
    } else {
      return window.Promise.resolve({
        status: 200
      })
    }
  },
  abort() {
    if (this.active) {
      this.cts.cancel();
      this.cts = axios.CancelToken.source() ;
    }
  },
  active: false,
  cts: axios.CancelToken.source()
}

