import React from 'react';

export default class LogOut extends React.Component {
  constructor(props){
    super(props);
    props.onLogout();
  }
  render(){
    return (
      <div></div>
    )
  }
}
