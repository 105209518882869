import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import utils from '../js/utils';
import account from '../js/account';
import billing from '../js/billing';
import mailer from '../js/mailer';

export default class Panel extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      error: '',
      success: '',
      info: '',
      isRequesting: false,
      loginPrompt: false,
      billingPrompt: false,
      shippingAddressPrompt: false,
      tokenPrompt: false,
      isInitialized: false
    }

    this.onPurchase = this.onPurchase.bind(this);
    this.purchase = this.purchase.bind(this);
  }
  onPurchase(priceId, name, price) {
    let self = this;
    this.setState({
      loginPrompt: false,
      billingPrompt: false,
      shippingAddressPrompt: false,
      tokenPrompt: false
    })
    if (!this.props.isLoggedIn) {
      this.setState({
        loginPrompt: true
      })
    } else {
      if (this.props.stripeBillingMethods.length === 0) {
        this.setState({
          billingPrompt: true
        })
      } else if (this.props.shippingAddress !== undefined && Object.keys(this.props.shippingAddress).length === 0){
        this.setState({
          shippingAddressPrompt: true
        })
      } else {
        this.setState({
          success: '',
          info: '',
          error: ''
        });

        self.props.showModal('Review Purchase Details', '<table class="receipt"><tbody><tr><td class="note">Please review the following information and hit "Confirm" when you are ready to finalize the purchase.</td><td></td></tr><tr><td>&nbsp;</td><td></td></tr><tr><td>&nbsp;</td><td></td></tr><tr><td></td><td>&nbsp;</td></tr><tr><td>' + name + ':</td><td style="text-align: right;">$' + (price / 100).toFixed(2) + '</td></tr><tr><td>Total:</td><td class="total"><b>$' + (price / 100).toFixed(2) + '</b></td></tr></tbody></table>', () => {self.purchase(priceId, 'purchase')});
      }
    }
  }
  purchase(priceId, kind) {
    let self = this;
    let tokenizedStr = utils.genTokenizedString();
    if (tokenizedStr) {
      self.setState({
        isRequesting: true,
        success: '',
        info: 'Processing payment...',
        error: ''
      });
      billing.purchase(tokenizedStr, priceId, kind).then(function(res) {
        if (res.status == 200) {
          self.setState({
            success: '',
            info: 'Purchase successful. Emailing receipt...',
            error: ''
          });
          self.props.onPurchase();
          let purchaseDetails = res.data.data;
          mailer.sendReceipt({
            template: kind,
            recipient: self.props.user.email,
            subject: 'Purchase Receipt from Adyn',
            reference_id: purchaseDetails.reference_id,
            name: purchaseDetails.name,
            price: purchaseDetails.price,
            amount: purchaseDetails.amount
          }).then(function(res2) {
            if (res2.status == 200) {
              self.setState({
                info: '',
                success: 'Email receipt was delivered to your inbox. Thank you for your purchase.',
                isRequesting: false
              })
            } else {
              self.setState({
                info: '',
                error: res2.statusText,
                isRequesting: false
              })
            }
          });
        } else {
          if (res.status !== 20) {
            self.setState({
              success: '',
              error: res.statusText,
              info: '',
              isRequesting: false
            });
          }
        }
      })
    } else {
      self.setState({
        success: '',
        error: '',
        info: '',
        tokenPrompt: true
      })
    }
  }
  render(){
    return (
      <div className="grid panel">
        <div className="col col-lg text-center">
          <img src={this.props.image} />
        </div>
        <div className="col col-lg">
          <p className="badge">{this.props.slogan}</p>
          <h1>{this.props.name}</h1>
          {this.props.description && this.props.description !== 'none' && <p className="description">{this.props.description}</p>}
          <ul>
            {this.props.features.map((f) => {
              return (<li key={f}>+ {f}</li>)
            })}
          </ul>
          <div className="grid">
            <p>
              {this.props.isLoggedIn ?
                <button className="success" onClick={() => this.onPurchase(this.props.id, this.props.name, this.props.amount)} disabled={this.state.isRequesting}>Purchase</button>
              :
                <Link to="/checkout" className="button success">Purchase</Link>
              }
            </p>
            <p className="full-price">${this.props.fullPrice / 100} USD</p>
            <p className="price">${this.props.amount / 100} USD</p>
          </div>
          <div>
            {this.state.info !== '' && <div className="alert info">{this.state.info}</div>}
            {this.state.success !== '' && <div className="alert success">{this.state.success}</div>}
            {this.state.error !== '' && <div className="alert danger">{this.state.error}</div>}
            {this.state.billingPrompt && <div className="alert info">Please add at least one payment method to <Link to="/">your profile</Link> to make purchases</div>}
            {this.state.shippingAddressPrompt && <div className="alert info">Please add at least one shipping address to <Link to="/">your profile</Link> to make purchases</div>}
            {this.state.loginPrompt && <div className="alert info">You must <Link to={'/login?redirect=/shop'}>log in</Link> to make purchases</div>}
            {this.state.tokenPrompt && <div className="alert info">Session token missing or expired. Please <Link to={'/login?redirect=/shop'}>log in</Link> again</div>}
          </div>
          <hr/>
          <p className="shipping-details">*{this.props.shippingDetails}</p>
          <p className="offers">{this.props.offers}</p>

        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  user: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,
  features: PropTypes.array,
  isRequesting: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  stripeCustomerId: PropTypes.string,
  stripeBillingMethods: PropTypes.array,
  shippingAddress: PropTypes.object,
  url: PropTypes.string,
  image: PropTypes.string,
  slogan: PropTypes.string,
  fullPrice: PropTypes.string,
  shippingDetails: PropTypes.string,
  offers: PropTypes.string,
  onPurchase: PropTypes.func
}
