import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Sidebar from '../components/Sidebar';
import mailer from '../js/mailer';
import utils from '../js/utils';

export default class Support extends React.Component {
  constructor(){
    super();

    this.state = {
      question: '',
      details: '',
      category: '',
      faq: [{
        q: 'Is adyn right for me?',
        a: '<ul><li>I’m located in the U.S. (excluding NY, NJ, RI)</li><li>I’m over 18 years of age</li><li>I use or am interested in using birth control</li></ul><p>If these describe you, then yes! Whether you are starting birth control for the first time, already on birth control but want to explore other methods, or just want to learn more about your unique body—adyn can help.</p>'
      }, {
        q: 'Where do you operate?',
        a: '<p>Lorem ipsum Lorem ipsum horem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</p>'
      }, {
        q: 'What birth control methods are included in your recommendations?',
        a: '<p>Lorem ipsum Lorem ipsum horem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</p>'
      }, {
        q: 'Why doesn’t my doctor do this?',
        a: '<p>Lorem ipsum Lorem ipsum horem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</p>'
      }, {
        q: 'When will you launch?',
        a: ''
      }, {
        q: 'How are you different from other online birth control providers?',
        a: ''
      }, {
        q: 'Can I use adyn if I already like the birth control that I am on?',
        a: ''
      }, {
        q: 'Do you accept insurance/HSA?',
        a: ''
      }, {
        q: 'What do you do with the information you collect?',
        a: ''
      }, {
        q: 'Why spit?',
        a: ''
      }, {
        q: 'Why a finger prick?',
        a: ''
      }, {
        q: 'What if I need help with my collection?',
        a: ''
      }],
      info: '',
      success: '',
      error: ''
    }

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onDetailsChange = this.onDetailsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSearchChange(evt) {
    this.setState({question: evt.target.value});
  }
  onCategoryChange(evt) {
    this.setState({category: evt.target.value});
  }
  onDetailsChange(evt) {
    this.setState({details: evt.target.value});
  }
  onSubmit(evt) {
    evt.preventDefault();
    let self = this;
    this.setState({
      info: 'Submitting...',
      success: '',
      error: ''
    })
    var payload = {
      app: 'portal',
      template: 'support',
      sender: this.props.user.email,
      category: this.state.category,
      question: this.state.question,
      details: this.state.details
    }
    mailer.sendEmail(payload).then(function(res) {
      if (res.status == 200) {
        self.setState({
          success: res.statusText,
          info: ''
        })
      } else {
        if (res.status !== 20) {
          self.setState({
            success: '',
            info: '',
            error: ''
          })
        }
      }
    })
  }
  render(){
    const lowerCasedFilter = this.state.question.toLowerCase();
    const filteredFaq = this.state.faq.filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowerCasedFilter)
      );
    })
    return (
      <div>
        <Sidebar location={this.props.location} />
        <div className="content">
          <div className="container">
            <div className="page support">
              <div className="card card-lg">
                <div className="card-header">
                  <h1>Let Us Know How We Can Help You</h1>
                </div>
                <div className="card-body">
                  <input type="text" name="question" id="question" value={this.state.question} onChange={this.onSearchChange} placeholder="Begin typing your question..."/>
                </div>
                <ul className="accordion">
                  {filteredFaq.map((f, i) => {
                    return (
                      <li key={i} className="tab">
                        <input type="checkbox" id={'chck' + i} />
                        <label className="tab-label" htmlFor={'chck' + i}>{f.q}</label>
                        <article className="tab-content">
                          {ReactHtmlParser(f.a)}
                        </article>
                      </li>
                    )
                  })}
                </ul>
                <br/>
                <br/>
                <h2>Did You Find Your Answer? If Not, Contact Us with the Following Form.</h2>
                <div>
                  <form id="support-form" onSubmit={this.onSubmit} className="double">
                    <fieldset className="full">
                      <label>
                        <p>Question</p>
                        <input type="text" name="question" id="support-question" value={this.state.question} onChange={this.onSearchChange} required />
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      <label>
                        <p>Category</p>
                        <select id="support-category" value={this.state.category} onChange={this.onCategoryChange} required>
                          <option value=""></option>
                          <option value="general">General question</option>
                          <option value="kit">Question about the product</option>
                          <option value="doctor">Question for a physician</option>
                          <option value="scheduling">Scheduling a consult</option>
                        </select>
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      <label>
                        <p>Details</p>
                        <textarea name="details" id="support-details" value={this.state.details} onChange={this.onDetailsChange} rows="10"/>
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      <input type="submit" id="support-submit" className="full" value="Submit" />
                    </fieldset>
                    {this.state.info !== '' && <div className="alert info">{this.state.info}</div>}
                    {this.state.success !== '' && <div className="alert success">{this.state.success}</div>}
                    {this.state.error !== '' && <div className="alert danger">{this.state.error}</div>}
                  </form>
                </div>
          </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

