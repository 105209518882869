import React from 'react';
import { Link } from 'react-router-dom';

export default class Footr extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
      <footer className="green-bg white-text" id="site-footer">
        <div className="wrap">
          <div className="footer-sec">
            <div className="adyn-logo">
              <svg viewBox="0 0 81 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>adyn logo</title>
                <path d="M16.4879 19.7544C16.4879 21.8232 16.7533 23.9819 17.6377 24.2517C17.8146 24.3417 17.9031 24.4316 17.9031 24.5216C17.9031 24.6565 17.7704 24.7464 17.5935 24.7464C17.4608 24.7464 16.709 24.7014 16.311 24.7014C15.913 24.7014 15.4708 24.7464 15.117 24.7464C14.8517 24.7464 14.719 24.5665 14.719 24.4766C14.719 23.6671 14.2768 22.3179 14.1883 21.3735H14.1441C12.7289 24.2067 10.2967 25.1512 7.28947 25.1512C4.14961 25.1512 0.390625 23.4872 0.390625 19.8893C0.390625 14.8524 7.77592 14.0878 11.4465 14.0878H13.923C13.923 10.2202 12.8616 7.83661 8.74884 7.83661C6.49345 7.83661 2.82291 9.09585 2.51335 11.7043C2.51335 11.7942 2.46912 11.8392 2.33645 11.8392C2.20378 11.8392 2.24801 11.8392 2.20378 11.7493L1.31932 8.60115C3.61893 7.56677 6.09544 7.0271 8.61617 7.0271C14.4979 7.0271 16.4879 9.63553 16.4879 13.4582V19.7544ZM11.4907 14.9423C8.08549 14.9423 3.48626 15.6169 3.48626 19.9793C3.48626 22.8576 5.69743 24.3417 8.30661 24.3417C13.1712 24.3417 13.8787 20.3841 13.8787 16.3815V14.9423H11.4907Z"/>
                <path d="M38.1129 0.820312V17.4153C38.1129 21.5528 38.4667 23.4867 39.2627 24.0263C39.528 24.2062 39.7049 24.3861 39.7049 24.4761C39.7049 24.566 39.6165 24.656 39.3954 24.656C38.8647 24.656 38.3782 24.521 37.936 24.521C37.4938 24.521 36.9188 24.656 36.4766 24.656L35.6806 21.0131C34.1328 23.6216 31.8774 25.1507 28.826 25.1507C23.7403 25.1956 19.8486 21.5078 19.8486 16.336C19.8486 10.7144 23.9172 7.07155 29.2682 7.07155C32.0101 7.07155 34.3981 8.69057 35.6806 9.99479V5.40755C35.6806 4.23825 35.5037 2.75415 34.5308 2.43934C34.0886 2.30442 34.0001 2.25945 34.0001 2.1695C34.0001 2.03458 34.1328 1.98961 34.3097 1.89966L38.1129 0.820312ZM22.9885 16.336C22.9885 20.1587 24.7574 24.3861 29.0029 24.3861C33.9559 24.3861 35.6806 20.4285 35.6806 15.9312C35.6806 11.4339 33.9117 7.92603 29.0913 7.92603C24.4479 7.88106 22.9885 12.4233 22.9885 16.336Z"/>
                <path d="M50.2747 25.1505C49.5671 26.6796 48.5942 28.0737 47.4002 29.288C45.8523 30.8171 44.2161 31.3118 42.0934 31.2668L42.9778 28.3885C43.0663 28.2087 43.1105 28.0737 43.1547 28.0737C43.1989 28.0737 43.2874 28.0737 43.3316 28.1187C43.7296 28.7933 44.4814 29.1531 45.2332 29.1081C47.0906 29.1081 49.1691 26.6346 49.1691 24.7907C49.1691 24.0262 48.9922 23.2616 48.6384 22.5421L43.4201 11.8835C42.4029 9.76976 41.5185 8.55549 40.8551 8.19571C40.5898 8.06079 40.3687 7.83593 40.3687 7.74598C40.3687 7.56609 40.5455 7.52112 40.7667 7.52112C41.1647 7.52112 41.8722 7.61106 43.1105 7.61106C44.1276 7.61106 45.0563 7.52112 45.4101 7.52112C45.9408 7.52112 46.0292 7.65604 46.0292 7.74598C46.0292 7.83593 45.7639 8.06079 45.4986 8.19571C45.189 8.42058 44.9679 8.82533 44.9679 9.23009C44.9679 9.76976 45.4986 11.074 46.2504 12.4681L50.9823 21.6876L53.9894 15.6612C55.2277 13.2777 56.3775 10.8491 56.3775 9.67982C56.3775 9.0502 56.0237 8.51052 55.493 8.19571C55.3162 8.06079 54.8739 7.83593 54.8739 7.74598C54.9181 7.61106 55.0066 7.52112 55.1393 7.52112C55.3161 7.52112 56.4217 7.56609 57.262 7.56609C58.5445 7.56609 59.1636 7.52112 59.4289 7.52112C59.5616 7.52112 59.7385 7.65604 59.7385 7.70101C59.7385 7.74598 59.6058 7.8809 59.5174 7.97085C58.4118 8.78036 57.0851 11.119 55.5815 14.2221L50.2747 25.1505Z"/>
                <path d="M64.9569 7.56611V11.3438H65.0454C65.9299 8.55552 68.8044 7.07141 71.7231 7.07141C75.1283 7.07141 78.7989 8.69044 78.7989 13.4126V19.8887C78.7989 23.5315 79.02 23.8913 79.816 24.2511C80.1698 24.431 80.3909 24.5659 80.3909 24.6558C80.3909 24.7458 80.214 24.7907 80.0813 24.7907C79.7275 24.7907 78.5335 24.7458 77.4722 24.7458C76.4108 24.7458 75.2168 24.7907 74.7745 24.7907C74.5092 24.7907 74.3323 24.7458 74.3323 24.7008C74.3323 24.5209 74.8188 24.386 74.9956 24.2511C76.0128 23.6664 76.1897 21.8675 76.1897 19.439V14.9417C76.1897 9.32006 73.8016 7.88092 71.0598 7.88092C67.6103 7.88092 65.1781 11.119 65.1781 14.9866V20.1136C65.1781 22.4971 65.355 23.6214 66.3279 24.296C66.6374 24.4759 66.9028 24.6108 66.9028 24.7008C66.9028 24.7907 66.8143 24.7907 66.6374 24.7907C66.1068 24.7907 64.8685 24.7458 63.9398 24.7458C63.0111 24.7458 61.8171 24.7907 61.2422 24.7907C61.0653 24.7907 60.9326 24.7458 60.9326 24.6108C60.9326 24.4759 61.2422 24.431 61.5517 24.2061C62.5689 23.6214 62.7016 21.3278 62.7016 19.4839V12.1084C62.7016 9.58989 62.1709 9.14017 61.596 8.96027C61.3306 8.87033 61.198 8.73541 61.198 8.64546C61.198 8.55552 61.4633 8.46557 61.596 8.46557L64.9569 7.56611Z"/>
              </svg>
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="footer-sec">
            <ul className="sitewide-links">
              <li><a href="https://www.adyn.com">Home</a></li>
              <li><a href="https://www.adyn.com/your-story" target="_blank">Your Story</a></li>
              <li><a href="https://www.adyn.com/about-us" target="_blank">Our Story</a></li>
              <li><a href="https://www.adyn.com/blog" target="_blank">Mind the Gap Blog</a></li>
              <li><a href="https://www.adyn.com/rewards/join" target="_blank">Rewards Program</a></li>
              <li><a href="https://www.adyn.com/faq" target="_blank">FAQ</a></li>
              <li><a href="https://www.adyn.com/careers" target="_blank">Careers</a></li>
              <li><a href="mailto:hello@adyn.com" target="_blank">Contact</a></li>
              <li><Link to="/checkout">Purchase</Link></li>
              <li><Link to="/login">My Adyn</Link></li>
            </ul>
          </div>
          <div className="footer-sec">
            <ul className="social-links">
              <li>
                <a href="https://www.instagram.com/adynhealth/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <title>instagram icon</title>
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/adynhealth">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <title>facebook icon</title>
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/adynhealth">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <title>twitter icon</title>
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                  </svg>
                </a>
              </li>
            </ul>
            <ul className="privacy-terms">
              <li><a href="https://www.iubenda.com/privacy-policy/94836304" target="_blank">Privacy Policy</a></li>
              <li><a href="https://www.adyn.com/terms-of-service" target="_blank">Terms of Service</a></li>
              <li>&copy; 2021 Adyn</li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}
