import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export default class Sidebar extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    return (
      <div className="sidebar">
        <Link to="/" className={this.props.location.pathname == '/' ? 'active' : ''}>My Adyn</Link>
        <Link to="/profile" className={this.props.location.pathname == '/profile' ? 'active' : ''}>My Profile</Link>
        <Link to="/medical-bio" className={this.props.location.pathname == '/medical-bio' || this.props.location.pathname == '/medical-bio-1' || this.props.location.pathname == '/medical-bio-2' ? 'active' : ''}>Medical Bio</Link>
        <Link to="/orders" className={this.props.location.pathname == '/orders' ? 'active' : ''}>My Orders</Link>
        <Link to="/shop" className={this.props.location.pathname == '/shop' ? 'active' : ''}>Shop</Link>
      </div>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.object
}
