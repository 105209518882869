import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import equal from 'fast-deep-equal';
import utils from '../../js/utils';
import account from '../../js/account';
import billing from '../../js/billing';
import addresses from '../../js/addresses';
import mailer from '../../js/mailer';
import auth from '../../js/auth';
import StripeCheckoutButton from '../StripeCheckoutButton'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Sidebar from '../../components/Sidebar';

export default class Profile extends React.Component {
  constructor(props){
    super(props);
    const user = props.user !== undefined ? props.user : {};
    const shippingAddress = props.shippingAddress !== undefined ? props.shippingAddress : {};

    this.state = {
      email: utils.replaceNullString(user.email),
      phone: utils.replaceNullString(user.phone),
      firstName: utils.replaceNullString(user.firstName),
      lastName: utils.replaceNullString(user.lastName),
      preferredName: utils.replaceNullString(user.preferredName),
      sex: utils.replaceNullString(user.sex),
      gender: utils.replaceNullString(user.gender),
      pronouns: (user.pronouns !== 'he/him' && user.pronouns !== 'she/her' && user.pronouns !== 'they/them') ? 'other' : utils.replaceNullString(user.pronouns),
      otherPronouns: (user.pronouns !== 'he/him' && user.pronouns !== 'she/her' && user.pronouns !== 'they/them') ? utils.replaceNullString(user.pronouns) : '',
      zip: utils.replaceNullString(user.zip),
      dateOfBirth: utils.replaceNullUndefined(user.dateOfBirth),
      newEmail: utils.replaceNullString(user.newEmail),
      stripeCustomerId: utils.replaceNullString(user.stripeCustomerId),
      shippingFirstName: utils.replaceNullString(shippingAddress.firstName),
      shippingLastName: utils.replaceNullString(shippingAddress.lastName),
      shippingAddress1: utils.replaceNullString(shippingAddress.address1),
      shippingAddress2: utils.replaceNullString(shippingAddress.address2),
      shippingCity: utils.replaceNullString(shippingAddress.city),
      shippingState: utils.replaceNullString(shippingAddress.state),
      shippingCountry: utils.replaceNullString(shippingAddress.country),
      shippingZip: utils.replaceNullString(shippingAddress.zip),
      shippingDeliveryInstructions: utils.replaceNullString(shippingAddress.deliveryInstructions),
      emailPref: user.emailPref !== undefined ? user.emailPref : '',
      user: user,
      pendingNewEmail: false,
      profileProgress: 0,
      updateAlert: '',
      shippingAlert: '',
      addShippingAlert: '',
      billingAlert: '',
      dobAlert: '',
      zipAlert: '',
      deactivationPrompt: '',
      cancelPrompt: ''
    }

    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onPreferredNameChange = this.onPreferredNameChange.bind(this);
    this.onSexChange = this.onSexChange.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
    this.onPronounsChange = this.onPronounsChange.bind(this);
    this.onOtherPronounsChange = this.onOtherPronounsChange.bind(this);
    this.onDateOfBirthChange = this.onDateOfBirthChange.bind(this);
    this.onZipChange = this.onZipChange.bind(this);

    this.onShippingFirstNameChange = this.onShippingFirstNameChange.bind(this);
    this.onShippingLastNameChange = this.onShippingLastNameChange.bind(this);
    this.onShippingAddress1Change = this.onShippingAddress1Change.bind(this);
    this.onShippingAddress2Change = this.onShippingAddress2Change.bind(this);
    this.onShippingCityChange = this.onShippingCityChange.bind(this);
    this.onShippingStateChange = this.onShippingStateChange.bind(this);
    this.onShippingCountryChange = this.onShippingCountryChange.bind(this);
    this.onShippingZipChange = this.onShippingZipChange.bind(this);
    this.onShippingDeliveryInstructionsChange = this.onShippingDeliveryInstructionsChange.bind(this);
    this.onNewEmailChange = this.onNewEmailChange.bind(this);
    this.onEmailPrefChange = this.onEmailPrefChange.bind(this);
    this.onSearchedAddressChange = this.onSearchedAddressChange.bind(this);
    this.onCustomerCreated = this.onCustomerCreated.bind(this);
    this.onStripeBillingMethodAdded = this.onStripeBillingMethodAdded.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.processPayload = this.processPayload.bind(this);
    this.resendVerificationEmail = this.resendVerificationEmail.bind(this);
    this.deleteStripeBillingMethod = this.deleteStripeBillingMethod.bind(this);
    this.addShippingAddress = this.addShippingAddress.bind(this);
    this.deleteShippingAddress = this.deleteShippingAddress.bind(this);
    this.onDeactivateAccount = this.onDeactivateAccount.bind(this);
    this.archiveUser = this.archiveUser.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.user, prevProps.user)) {
      let user = this.props.user
      console.log(user.pronouns)
      this.setState({
        email: utils.replaceNullString(user.email),
        phone: utils.replaceNullString(user.phone),
        firstName: utils.replaceNullString(user.firstName),
        lastName: utils.replaceNullString(user.lastName),
        preferredName: utils.replaceNullString(user.preferredName),
        sex: utils.replaceNullString(user.sex),
        gender: utils.replaceNullString(user.gender),
        pronouns: (user.pronouns !== 'he/him' && user.pronouns !== 'she/her' && user.pronouns !== 'they/them') ? 'other' : utils.replaceNullString(user.pronouns),
        newEmail: utils.replaceNullString(user.newEmail),
        emailPref: user.emailPref !== undefined ? user.emailPref : '',
        user: user
      });
      console.log(user)
    }
  }
  componentWillUnmount() {
    account.abort();
    billing.abort();
    addresses.abort();
  }
  onPhoneChange(evt) {
    this.setState({phone: utils.validatePhoneNumber(evt.target.value)})
  }
  onFirstNameChange(evt) {
    this.setState({firstName: evt.target.value});
  }
  onLastNameChange(evt) {
    this.setState({lastName: evt.target.value});
  }
  onPreferredNameChange(evt) {
    this.setState({preferredName: evt.target.value});
  }
  onSexChange(evt) {
    this.setState({sex: evt.target.value})
  }
  onGenderChange(evt) {
    this.setState({gender: evt.target.value})
  }
  onPronounsChange(evt) {
    this.setState({pronouns: evt.target.value})
  }
  onOtherPronounsChange(evt) {
    this.setState({otherPronouns: evt.target.value})
  }
  onZipChange(evt) {
    var zip = utils.validateZip(evt.target.value)
    var newState = {
      zip: zip
    }
    if (!utils.isZipValid(zip)) {
      newState['zipAlert'] = 'Invalid zip'
    } else {
      newState['zipAlert'] = '';
    }
    this.setState(newState);
  }
  onDateOfBirthChange(evt) {
    var newState = {
      dateOfBirth: evt.target.value
    }
    if (!utils.isDateOfBirthValid(evt.target.value)) {
      newState['dobAlert'] = 'Must be 18 or older';
    } else {
      newState['dobAlert'] = '';
    }
    this.setState(newState);
  }
  onShippingFirstNameChange(evt) {
    this.setState({shippingFirstName: evt.target.value})
  }
  onShippingLastNameChange(evt) {
    this.setState({shippingLastName: evt.target.value})
  }
  onShippingAddress1Change(evt) {
    this.setState({shippingAddress1: evt.target.value})
  }
  onShippingAddress2Change(evt) {
    this.setState({shippingAddress2: evt.target.value})
  }
  onShippingCityChange(evt) {
    this.setState({shippingCity: evt.target.value})
  }
  onShippingStateChange(evt) {
    this.setState({shippingState: evt.target.value})
  }
  onShippingCountryChange(evt) {
    this.setState({shippingCountry: evt.target.value})
  }
  onShippingZipChange(evt) {
    this.setState({shippingZip: utils.validateZip(evt.target.value)})
  }
  onShippingDeliveryInstructionsChange(evt) {
    this.setState({shippingDeliveryInstructions: evt.target.value})
  }
  onNewEmailChange(evt) {
    this.setState({newEmail: evt.target.value});
  }
  onEmailPrefChange() {
    this.setState({emailPref: !this.state.emailPref});
  }
  onSearchedAddressChange(evt) {
    this.setState({searchedAddress: evt.target.value});
  }
  processPayload() {
    let payload = {}
    let user = this.props.user
    if (this.state.phone !== user.phone) {
      payload['phone'] = this.state.phone;
    }
    if (this.state.firstName !== user.firstName) {
      payload['first_name'] = this.state.firstName;
    }
    if (this.state.lastName !== user.lastName) {
      payload['last_name'] = this.state.lastName;
    }
    if (this.state.preferredName !== user.preferredName) {
      payload['preferred_name'] = this.state.preferredName;
    }
    if (this.state.sex !== user.sex) {
      payload['sex'] = this.state.sex;
    }
    if (this.state.gender !== user.gender) {
      payload['gender'] = this.state.gender;
    }

    var pronouns = this.state.otherPronouns !== '' ? this.state.otherPronouns : this.state.pronouns;
    if (pronouns !== user.pronouns) {
      payload['pronouns'] = pronouns;
    }
    if (this.state.zip !== user.zip) {
      payload['zip'] = this.state.zip;
    }
    if ((this.state.dateOfBirth !== user.dateOfBirth) && (this.state.dateOfBirth !== undefined && user.dateOfBirth !== null)) {
      payload['date_of_birth'] = this.state.dateOfBirth;
    }
    if (this.state.newEmail !== '' && this.state.newEmail !== this.state.email) {
      payload['new_email'] = this.state.newEmail;
    }

    if (this.state.emailPref !== user.emailPref) {
      payload['email_pref'] = this.state.emailPref;
    }

    return payload;
  }
  resendVerificationEmail() {
    let self = this;
    let tokenizedStr = utils.genTokenizedString();
    if (tokenizedStr) {
      self.setState({
        updateAlert: 'Resending...',
        shippingAlert: '',
        addShippingAlert: '',
        billingAlert: '',
        otpAlert: ''
      })
      account.reVerifyUser(tokenizedStr, this.props.user.id, {
        email: this.props.user.email,
        new_email: this.state.newEmail
      }).then(function(res) {
        if (res.status == 200) {
          mailer.sendEmail({
            template: 'verification_update',
            recipient: self.props.user.email,
            email: self.state.newEmail,
            app: 'portal',
            path: '/verify?u_code=' + res.data.data.u_code + '&email=' + encodeURIComponent(self.state.newEmail)
          }).then(function(res2) {
            if (res2.status == 200) {
              self.setState({
                updateAlert: 'New email pending verification. Please check your ' + self.props.user.email + ' inbox to continue the registration process.'
              })
            } else {
              self.setState({
                updateAlert: res2.statusText
              })
            }
          });
        } else {
          if (res.status !== 20) {
            self.setState({
              updateAlert: res.statusText
            })
          }
        }
      })
    }
  }
  onCustomerCreated(res) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      addShippingAlert: '',
      billingAlert: 'Updating user details...',
      otpAlert: ''
    });

    if (res.status === 200) {
      let stripeCustomer = res.data.data;
      let tokenizedStr = utils.genTokenizedString();
      account.updateUser(tokenizedStr, this.props.user.id, {'stripe_customer_id': stripeCustomer.stripe_customer_id}).then(function(res2) {
        if (res2.status === 200) {
          self.props.onUpdateStripeBillingMethods(stripeCustomer.billing_methods, function() {
            self.setState({
              billingAlert: 'Payment details updated successfully'
            });
          })
          auth.refresh(tokenizedStr);
        } else {
          if (res2.status != 20) {
            billing.deleteCustomer(tokenizedStr, stripeCustomer.customer_id).then(function() {
              self.setState({
                billingAlert: 'There was an issue adding your payment method. Please try again.'
              });
            })
          }
        }
      });
    } else {
      if (res.status !== 20) {
        self.setState({
          billingAlert: res.statusText
        })
      }
    }
  }
  onStripeBillingMethodAdded(res) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      addShippingAlert: '',
      billingAlert: '',
      otpAlert: ''
    })
    if (res.status == 200) {
      let stripeBillingMethods = [...this.props.stripeBillingMethods];
      stripeBillingMethods.push(res.data.data);
      self.props.onUpdateStripeBillingMethods(stripeBillingMethods, function() {
        self.setState({
          billingAlert: res.statusText
        })
      })
    } else {
      if (res.status !== 20) {
        self.setState({
          billingAlert: res.statusText
        })
      }
    }
  }
  deleteStripeBillingMethod(id) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      billingAlert: 'Deleting payment method...',
      otpAlert: ''
    })
    let tokenizedStr = utils.genTokenizedString();
    billing.deleteStripeBillingMethod(tokenizedStr, id).then(function(res) {
      if (res.status == 200) {
        let stripeBillingMethods = [...self.props.stripeBillingMethods]
        utils.removeArrayItemById(stripeBillingMethods, id);
        self.props.onUpdateStripeBillingMethods(stripeBillingMethods, function() {
          self.setState({
            billingAlert: res.statusText
          })
        })
      } else {
        if (res.status !== 20) {
          self.setState({
            billingAlert: res.statusText
          })
        }
      }
    })
  }
  addShippingAddress(evt) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      addShippingAlert: 'Adding shipping address',
      billingAlert: '',
      otpAlert: ''
    })
    let tokenizedStr = utils.genTokenizedString();
    var payload = {
      'first_name': this.state.shippingFirstName,
      'last_name': this.state.shippingLastName,
      'address1': this.state.shippingAddress1,
      'address2': this.state.shippingAddress2,
      'city': this.state.shippingCity,
      'state': this.state.shippingState,
      'country': this.state.shippingCountry,
      'zip': this.state.shippingZip,
      'delivery_instructions': this.state.shippingDeliveryInstructions
    }
    addresses.addShippingAddress(tokenizedStr, payload).then(function(res) {
      if (res.status == 200) {
        self.props.onUpdateShippingAddress({
          firstName: self.state.shippingFirstName,
          lastName: self.state.shippingLastName,
          address1: self.state.shippingAddress1,
          address2: self.state.shippingAddress2,
          city: self.state.shippingCity,
          state: self.state.shippingState,
          country: self.state.shippingCountry,
          zip: self.state.shippingZip,
          deliveryInstructions: self.state.shippingDeliveryInstructions
        }, () => {
          self.setState({
            addShippingAlert: res.statusText
          })

        })
      } else {
        if (res.status !== 20) {
          self.setState({
            addShippingAlert: res.statusText
          })
        }
      }
    })
  }
  deleteShippingAddress(id) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: 'Deleting shipping address...',
      addShippingAlert: '',
      billingAlert: '',
      otpAlert: ''
    })
    let tokenizedStr = utils.genTokenizedString();
    addresses.deleteShippingAddress(tokenizedStr, id).then(function(res) {
      if (res.status == 200) {
        self.setState({
          shippingAddress: undefined,
          shippingAlert: res.statusText
        });
      } else {
        if (res.status !== 20) {
          self.setState({
            shippingAlert: res.statusText
          });
        }
      }
    })
  }
  onDeactivateAccount(evt) {
    evt.preventDefault();
    this.setState({deactivationPrompt: 'Are you sure you want to deactivate your account?'})
  }
  archiveUser() {
    let self = this;
    if (this.props.stripeBillingMethods.length > 0) {
      alert('Please delete all payment methods before deactivating your account');
    } else {
      let tokenizedStr = utils.genTokenizedString();
      if (tokenizedStr) {
        this.setState({
          updateAlert: '',
          shippingAlert: '',
          addShippingAlert: '',
          billingAlert: '',
          otpAlert: 'Deactivating...'
        })
        account.archiveUser(tokenizedStr, this.props.user.id).then(function(res) {
          if (res.status == 200) {
            self.props.onOptOut();
            self.props.onLogout();
          } else {
            if (res.status !== 20) {
              self.setState({
                otpAlert: res.statusText
              })
            }
          }
        })
      }
    }
  }
  onSubmit(evt) {
    evt.preventDefault();
    let payload = this.processPayload();

    this.setState({
      updateAlert: utils.isEmptyObject(payload) ? 'No changes to profile were detected' : '',
      shippingAlert: '',
      addShippingAlert: '',
      billingAlert: '',
      otpAlert: ''
    })
    if (!utils.isEmptyObject(payload)) {
      let self = this;
      let tokenizedStr = utils.genTokenizedString();
      if (tokenizedStr) {
        self.setState({
          updateAlert: 'Attempting update...',
        })
        account.updateUser(tokenizedStr, this.props.user.id, payload).then(function(res) {
          if (res.status == 200) {
            let modifiedFields = utils.formatUnderscoredFields(payload);
            let updatedStateUser = utils.updateObjWithObj(self.state.user, modifiedFields);
            self.props.onUpdateUser(updatedStateUser);
            let newState = {
              updateAlert: res.statusText
            }
            if (payload.newEmail !== undefined) {
              newState.pendingNewEmail = true;
              mailer.sendEmail({
                template: 'verification_update',
                recipient: res.data.data.old_email,
                email: res.data.data.new_email,
                app: 'portal',
                path: '/verify?u_code=' + res.data.data.u_code + '&email=' + encodeURIComponent(res.data.data.new_email)
              }).then(function(res2) {
                if (res2.status == 200) {
                  self.setState({
                    updateAlert: 'New email pending verification. Please check your ' + res.data.data.old_email + ' inbox to continue the registration process.'
                  })
                } else {
                  self.setState({
                    updateAlert: res2.statusText
                  })
                }
              });
            }
            self.setState(newState)
          } else {
            if (res.status !== 20) {
              self.setState({
                updateAlert: res.statusText
              })
            }
          }
        })
      }
    }
  }
  render(){
    return (
      <div>
        <Sidebar location={this.props.location} />
        <div className="content">
          <div className="container">
            <div className="page profile">
              <div className="card card-lg">
                <div className="card-header">
                  <h1>Profile</h1>
                </div>
                <div className="card-body">
                  <form id="profile-form" onSubmit={this.onSubmit} className="double">
                    <fieldset>
                      <label>
                        <p>Email address</p>
                        <input type="email" name="email" id="profile-email" value={this.state.email} disabled/>
                      </label>
                    </fieldset>
                    <fieldset>
                      <label>
                        <p>Phone</p>
                        <input type="text" name="phone" id="phone" value={this.state.phone} onChange={this.onPhoneChange} />
                      </label>
                    </fieldset>

                    <fieldset>
                      <label>
                        <p>First name</p>
                        <input type="text" name="first-name" id="profile-first-name" value={this.state.firstName} onChange={this.onFirstNameChange} />
                      </label>
                    </fieldset>
                    <fieldset>
                      <label>
                        <p>Last name</p>
                        <input type="text" name="last-name" id="profile-last-name" value={this.state.lastName} onChange={this.onLastNameChange} />
                      </label>
                    </fieldset>

                    <fieldset>
                      <label>
                        <p>Preferred name</p>
                        <input type="text" name="preferred-name" id="profile-preferred-name" value={this.state.preferredName} onChange={this.onPreferredNameChange} />
                      </label>
                    </fieldset>
                    {this.state.pronouns == 'other' ?
                      <fieldset>
                        <fieldset className="col col-md">
                          <label>
                            <p>Pronouns</p>
                            <select id="profile-pronouns" value={this.state.pronouns} onChange={this.onPronounsChange}>
                              <option value=""></option>
                              <option value="he/him">he/him</option>
                              <option value="she/her">she/her</option>
                              <option value="they/them">they/them</option>
                              <option value="other">other</option>
                            </select>
                          </label>
                        </fieldset>
                        <fieldset className="col col-md">
                          <label>
                            <p>Other Pronouns</p>
                            <input type="text" name="other-pronouns" id="profile-other-pronouns" value={this.state.otherPronouns} onChange={this.onOtherPronounsChange} />
                          </label>
                        </fieldset>
                      </fieldset>
                    :
                      <fieldset>
                        <label>
                          <p>Pronouns</p>
                          <select id="profile-pronouns" value={this.state.pronouns} onChange={this.onPronounsChange}>
                            <option value=""></option>
                            <option value="he/him">he/him</option>
                            <option value="she/her">she/her</option>
                            <option value="they/them">they/them</option>
                            <option value="other">other</option>
                          </select>
                        </label>
                      </fieldset>
                    }
                    <fieldset>
                      <label>
                        <p>Sex assigned at birth</p>
                        <select id="profile-sex" value={this.state.sex} onChange={this.onSexChange}>
                          <option value=""></option>
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                          <option value="i">Intersex</option>
                        </select>
                      </label>
                    </fieldset>
                    <fieldset>
                      <label>
                        <p>Gender</p>
                        <select id="profile-gender" value={this.state.gender} onChange={this.onGenderChange}>
                          <option value=""></option>
                          <option value="androgyne">Androgyne</option>
                          <option value="androgynous">Androgynous</option>
                          <option value="bigender">Bigender</option>
                          <option value="cis">Cis</option>
                          <option value="cisgender">Cisgender</option>
                          <option value="cis_female">Cis Female</option>
                          <option value="cis_male">Cis Male</option>
                          <option value="cis_man">Cis Man</option>
                          <option value="cis_woman">Cis Woman</option>
                          <option value="cisgender_female">Cisgender Female</option>
                          <option value="cisgender_male">Cisgender Male</option>
                          <option value="cisgender_man">Cisgender Man</option>
                          <option value="cisgender_woman">Cisgender Woman</option>
                          <option value="female">Female</option>
                          <option value="female_to_male">Female to Male</option>
                          <option value="ftm">FTM</option>
                          <option value="gender_fluid">Gender Fluid</option>
                          <option value="gender_nonconforming">Gender Nonconforming</option>
                          <option value="gender_questioning">Gender Questioning</option>
                          <option value="gender_variant">Gender Variant</option>
                          <option value="genderqueer">Genderqueer</option>
                          <option value="intersex">Intersex</option>
                          <option value="male">Male</option>
                          <option value="male_to_female">Male to Female</option>
                          <option value="mtf">MTF</option>
                          <option value="neither">Neither</option>
                          <option value="neutrois">Neutrois</option>
                          <option value="non-binary">Non-binary</option>
                          <option value="other">Other</option>
                          <option value="pangender">Pangender</option>
                          <option value="trans">Trans</option>
                          <option value="trans_female">Trans Female</option>
                          <option value="trans_male">Trans Male</option>
                          <option value="trans_man">Trans Man</option>
                          <option value="trans_person">Trans Person</option>
                          <option value="trans_woman">Trans Woman</option>
                          <option value="transfeminine">Transfeminine</option>
                          <option value="transgender">Transgender</option>
                          <option value="transgender_female">Transgender Female</option>
                          <option value="transgender_male">Transgender Male</option>
                          <option value="transgender_man">Transgender Man</option>
                          <option value="transgender_person">Transgender Person</option>
                          <option value="transgender_woman">Transgender Woman</option>
                          <option value="transmasculine">Transmasculine</option>
                          <option value="transsexual">Transsexual</option>
                          <option value="transsexual_female">Transsexual Female</option>
                          <option value="transsexual_male">Transsexual Male</option>
                          <option value="transsexual_man">Transsexual Man</option>
                          <option value="transsexual_person">Transsexual Person</option>
                          <option value="transsexual_woman">Transsexual Woman</option>
                          <option value="two-spirit">Two-Spirit</option>
                        </select>
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      <label>
                        <p>Date of Birth</p>
                        <input type="date" name="date-of-birth" id="profile-date-of-birth" value={this.state.dateOfBirth} onChange={this.onDateOfBirthChange} />
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      {this.state.dobAlert !== '' && <div className="alert info">{this.state.dobAlert}</div>}
                    </fieldset>
                    <fieldset className="full">
                      <label>
                        <p>Zip</p>
                        <input type="text" name="zip" id="profile-zip" value={this.state.zip} onChange={this.onZipChange} />
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      {this.state.zipAlert !== '' && <div className="alert info">{this.state.zipAlert}</div>}
                    </fieldset>
                    <fieldset className="full">
                      <label>
                        <p>New email address</p>
                        <input type="email" name="new-email" id="profile-new-email" value={this.state.newEmail} onChange={this.onNewEmailChange} />
                      </label>
                    </fieldset>
                    <fieldset className="full">
                      <label className="checkbox">
                        <p>Notifications</p>
                        <input
                          type="checkbox"
                          id="profile-subscribe"
                          checked={this.state.emailPref === true}
                          onChange={this.onEmailPrefChange}/>
                          Subscribe to notifications
                      { (this.state.user.new_email || this.state.pendingNewEmail) && <p><button onClick={this.resendVerificationEmail}>Resend verification email</button></p>}
                      </label>
                    </fieldset>
                    {this.state.updateAlert !== '' && <div className="alert info">{this.state.updateAlert}</div>}
                    <fieldset className="full">
                      <input type="submit" id="profile-update" value="Update Profile" disabled={this.state.dobAlert || this.state.zipAlert}/>
                    </fieldset>
                  </form>
                  <h3>My Shipping Address</h3>
                  <div>
                    {Object.keys(this.props.shippingAddress).length > 0
                    ?
                      <div>
                        <ul className="shipping-address">
                          <li><b>{this.props.shippingAddress.firstName} {this.props.shippingAddress.lastName}</b></li>
                          <li>{this.props.shippingAddress.address1}</li>
                          <li>{this.props.shippingAddress.address2}</li>
                          <li>{this.props.shippingAddress.city}, {this.props.shippingAddress.state} {this.props.shippingAddress.zip} {this.props.shippingAddress.country}</li>
                          <li>{this.props.shippingAddress.deliveryInstructions}</li>
                        </ul>
                      </div>
                    :
                      <ul className="shipping-address">
                        <li>No shipping address on file. Please add a shipping address using the form below.</li>
                      </ul>
                    }
                    {this.state.shippingAlert !== '' && <div className="alert info">{this.state.shippingAlert}</div>}
                    <div>
                      <h4>Add New Shipping Address</h4>
                      {!this.props.isFetchingShippingAddress ?
                        <div>
                          <form id="profile-form" onSubmit={this.onSubmit} className="double">
                            <fieldset>
                              <label>
                                <p>First Name</p>
                                <input type="text" name="shipping-first-name" id="profile-shipping-first-name" value={this.state.shippingFirstName} onChange={this.onShippingFirstNameChange} />
                              </label>
                            </fieldset>
                            <fieldset>
                              <label>
                                <p>Last Name</p>
                                <input type="text" name="shipping-last-name" id="profile-shipping-last-name" value={this.state.shippingLastName} onChange={this.onShippingLastNameChange} />
                              </label>
                            </fieldset>
                            <fieldset className="full">
                              <label>
                                <p>Address1</p>
                                <input type="text" name="shipping-address1" id="profile-shipping-address1" value={this.state.shippingAddress1} onChange={this.onShippingAddress1Change} />
                              </label>
                            </fieldset>
                            <fieldset className="full">
                              <label>
                                <p>Address2</p>
                                <input type="text" name="shipping-address2" id="profile-shipping-address2" value={this.state.shippingAddress2} onChange={this.onShippingAddress2Change} placeholder="Address 2"/>
                              </label>
                            </fieldset>
                            <fieldset>
                              <label>
                                <p>City</p>
                                <input type="text" name="shipping-city" id="profile-shipping-city" value={this.state.shippingCity} onChange={this.onShippingCityChange} />
                              </label>
                            </fieldset>
                            <fieldset>
                              <label>
                                <p>State</p>
                                <select id="shipping-state" value={this.state.shippingState} onChange={this.onShippingStateChange}>
                                  <option value=""></option>
                                  <option value="AL">Alabama</option>
                                  <option value="AK">Alaska</option>
                                  <option value="AZ">Arizona</option>
                                  <option value="AR">Arkansas</option>
                                  <option value="CA">California</option>
                                  <option value="CO">Colorado</option>
                                  <option value="CT">Connecticut</option>
                                  <option value="DE">Delaware</option>
                                  <option value="DC">District Of Columbia</option>
                                  <option value="FL">Florida</option>
                                  <option value="GA">Georgia</option>
                                  <option value="HI">Hawaii</option>
                                  <option value="ID">Idaho</option>
                                  <option value="IL">Illinois</option>
                                  <option value="IN">Indiana</option>
                                  <option value="IA">Iowa</option>
                                  <option value="KS">Kansas</option>
                                  <option value="KY">Kentucky</option>
                                  <option value="LA">Louisiana</option>
                                  <option value="ME">Maine</option>
                                  <option value="MD">Maryland</option>
                                  <option value="MA">Massachusetts</option>
                                  <option value="MI">Michigan</option>
                                  <option value="MN">Minnesota</option>
                                  <option value="MS">Mississippi</option>
                                  <option value="MO">Missouri</option>
                                  <option value="MT">Montana</option>
                                  <option value="NE">Nebraska</option>
                                  <option value="NV">Nevada</option>
                                  <option value="NH">New Hampshire</option>
                                  <option value="NM">New Mexico</option>
                                  <option value="NC">North Carolina</option>
                                  <option value="ND">North Dakota</option>
                                  <option value="OH">Ohio</option>
                                  <option value="OK">Oklahoma</option>
                                  <option value="OR">Oregon</option>
                                  <option value="PA">Pennsylvania</option>
                                  <option value="SC">South Carolina</option>
                                  <option value="SD">South Dakota</option>
                                  <option value="TN">Tennessee</option>
                                  <option value="TX">Texas</option>
                                  <option value="UT">Utah</option>
                                  <option value="VT">Vermont</option>
                                  <option value="VA">Virginia</option>
                                  <option value="WA">Washington</option>
                                  <option value="WV">West Virginia</option>
                                  <option value="WI">Wisconsin</option>
                                  <option value="WY">Wyoming</option>
                                </select>
                              </label>
                            </fieldset>
                            <fieldset>
                              <label>
                                <p>Zip code</p>
                                <input type="text" name="shipping-zip" id="profile-shipping-zip" value={this.state.shippingZip} onChange={this.onShippingZipChange} />
                              </label>
                            </fieldset>
                            <fieldset>
                              <label>
                                <p>Country</p>
                                <select id="shipping-country" value={this.state.shippingCountry} onChange={this.onShippingCountryChange}>
                                  <option value="US">United States</option>
                                </select>
                              </label>
                            </fieldset>
                            <fieldset className="full">
                              <label>
                                <p>Special delivery instructions</p>
                                <input type="text" name="shipping-delivery-instructions" id="profile-shipping-delivery-instructions" className="full" value={this.state.shippingDeliveryInstructions} onChange={this.onShippingDeliveryInstructionsChange} />
                              </label>
                            </fieldset>
                          </form>

                          {this.state.addShippingAlert !== '' && <div className="alert info">{this.state.addShippingAlert}</div>}
                          <button className="success" onClick={this.addShippingAddress} id="my-payment-methods">Add Shipping Address</button>
                        </div>
                      :
                        <p>Retrieving shipping address...</p>
                      }
                    </div>
                  </div>

                  <h3>My Payment Methods</h3>
                  <div className="grid">
                    {this.props.stripeBillingMethods.length > 0 &&
                      this.props.stripeBillingMethods.map((s, i) => {
                        return (
                          <div key={i} className="col col-lg">
                            <ul className="credit-card">
                              <li><b>{s.brand}</b> ends in <b>{s.last4}</b></li>
                              <li>Expires: {s.expires}</li>
                              <li><button className="danger small" onClick={() => { this.deleteStripeBillingMethod(s.id) }}>Delete</button></li>
                            </ul>
                          </div>)
                      })
                    }
                  </div>
                  <div>
                    {!this.props.isFetchingStripeCustomer ?
                      <div>
                        {this.state.billingAlert !== '' && <div className="alert info">{this.state.billingAlert}</div>}
                        {this.state.stripeCustomerId ?
                          <p><StripeCheckoutButton name="Add Card" amount={0} action="ATTACH_SOURCE" email={this.state.email} callback={this.onStripeBillingMethodAdded} /></p>
                        :
                          <p><StripeCheckoutButton name="Add Card" amount={0} action="CREATE_CUSTOMER" email={this.state.email} callback={this.onCustomerCreated} /></p>
                        }
                      </div>
                    :
                      <p>Retrieving billing information...</p>
                    }
                  </div>
                  <h3>&nbsp;</h3>
                  <div className="grid subscriptions">
                    <div className="col col-lg text-right">
                      <button id="profile-log-out" onClick={this.props.onLogout}>Log Out</button>
                      <form id="deactivation-form" onSubmit={this.onDeactivateAccount}>
                        <fieldset>
                          <input type="submit" value="Deactivate Account" className="danger"/>
                          {this.state.deactivationPrompt !== '' && <div>{this.state.deactivationPrompt} <button onClick={this.archiveUser}>Yes</button> <button onClick={() => {this.setState({deactivationPrompt: ''})}}>No</button></div>}
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Profile.propTypes = {
  user: PropTypes.object,
  isFetchingAddress: PropTypes.bool,
  isFetchingStripeCustomer: PropTypes.bool,
  onUpdateUser: PropTypes.func,
  onUpdateShippingAddress: PropTypes.func,
  onLogout: PropTypes.func,
  shippingAddress: PropTypes.object,
  stripeBillingMethods: PropTypes.array,
  location: PropTypes.object
}
