import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Civ
import Home from '../components/Home';

// Global
import FourZeroFour from '../components/FourZeroFour';
import Menu from '../components/Menu';
import Footr from '../components/Footr';
import Modal from '../components/Modal';

// Admin
import PoR from '../components/PoR';

// Account
import Signup from '../components/account/Signup';
import LogIn from '../components/account/LogIn';
import LogOut from '../components/LogOut';
import Verify from '../components/account/Verify';
import RequestReset from '../components/account/RequestReset';
import Reset from '../components/account/Reset';
import Profile from '../components/account/Profile';
import Shop from '../components/Shop';
import Checkout from '../components/Checkout';
import CompleteProfile from '../components/CompleteProfile';
import CompleteBio from '../components/CompleteBio';
import Orders from '../components/Orders';
import Activate from '../components/Activate';
import MyAdyn from '../components/MyAdyn';
import Support from '../components/Support';
import Schedule from '../components/Schedule';
import MedicalBio from '../components/MedicalBio';
import ThankYou from '../components/ThankYou';

// Utilities
import utils from './utils';
import auth from './auth';
import account from './account';
import billing from './billing';
import addresses from './addresses';
import bios from './bios';
import orders from './orders';
import shipments from './shipments';
import jotform from './jotform';
import {STRIPE_PK} from './keys';

const stripePromise = loadStripe(STRIPE_PK);

class Main extends React.Component {
  constructor(props){
    super(props);
    let isConsented = utils.getCookie('cookieConsentGiven') == 'true';
    if (!utils.getCookie('session')) {
      utils.setCookie('session', utils.uuidv4(), 365);
    }

    this.state = {
      isConsented: isConsented,
      isLoggedIn: true,
      isPoR: false,
      isFullyMounted: false,
      user: {},
      stripeCustomerId: '',
      showModal: false,
      modalTitle: '',
      modalMessage: '',
      modalConfirmMessage: '',
      modalCancelMessage: '',
      modalCallback: null,
      stripeBillingMethods: [],
      shippingAddress: {},
      medicalBios: [],
      invoiceItems: [],
      orders: [],
      shipments: [],
      surveys: [],
      telemedicineVisits: [],
      isMedicalBioStarted: true,
      isMedicalBioCompleted: true,
      isFetchingShippingAddress: true,
      isFetchingStripeCustomer: true,
      isFetchingMedicalBios: true,
      isFetchingOrders: true,
      isFetchingShipments: true
    };

    this.onConsent = this.onConsent.bind(this);
    this.toggleIsConsented = this.toggleIsConsented.bind(this);
    this.onOptOut = this.onOptOut.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onUpdateShippingAddress = this.onUpdateShippingAddress.bind(this);
    this.onUpdateStripeBillingMethods = this.onUpdateStripeBillingMethods.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.fetchOrders = this.fetchOrders.bind(this);
  }
  componentDidMount() {
    let self = this
    let tokenizedStr = utils.genTokenizedString()
    if (tokenizedStr) {
      auth.login(tokenizedStr).then(function(res) {
        if (res.status == 200) {
          self.onLogin(res);
        } else {
          self.onLogout();
        }
      })
    } else {
      self.onLogout();
    }
  }
  componentDidUpdate(prevProps){
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      /*
      if (this.state.isConsented) {
        utils.trackGAEvent('pageView')
      } else {
        utils.trackGAEvent('anon')
      }
      */
      utils.trackGAEvent('pageView')
    }
  }
  showModal(title, message, callback, confirmMessage, cancelMessage) {
    this.setState({
      modalTitle: title,
      modalMessage: message,
      modalConfirmMessage: confirmMessage,
      modalCancelMessage: cancelMessage,
      modalCallback: callback,
      showModal: true,
    })
  }
  hideModal() {
    this.setState({
      showModal: false
    })
  }
  onConsent(){
    this.setState({isConsented: true});
    utils.setCookie('cookieConsentGiven', 'true', 365);
  }
  toggleIsConsented(){
    this.setState({isConsented: !this.state.isConsented});
  }
  onOptOut(){
    this.setState({isConsented: false});
    utils.deleteCookie('cookieConsentGiven');
  }
  fetchOrders() {
    var self = this;
    var tokenizedStr = utils.genTokenizedString()
    if (tokenizedStr) {
      this.setState({
        isFetchingOrders: true,
        isFetchingInvoiceItems: true,
        isFetchingShipments: true
      })
      orders.fetchOrders(tokenizedStr).then(function(res) {
        orders.fetchStripeInvoiceItems(tokenizedStr).then(function(res2) {
          shipments.fetchShipments(tokenizedStr).then(function(res3) {
            if (res.status == 200) {
              self.setState({
                orders: res.data.data,
                isFetchingOrders: false
              })
            }
            if (res2.status == 200) {
              self.setState({
                invoiceItems: res2.data.data,
                isFetchingInvoiceItems: false
              })
            }
            if (res3.status == 200) {
              self.setState({
                shipments: res3.data.data,
                isFetchingShipments: false
              })
            }
          })
        })
      }).then(()=> {
        if (tokenizedStr) {
          jotform.fetchSubmission(tokenizedStr, '212216653542147').then(function(res4) {
            if (res4.status == 404) {
              self.setState({
                isMedicalBioStarted: false
              })
            }
          }).then(()=> {
            jotform.fetchSubmission(tokenizedStr, '212236136908151').then(function(res5) {
              if (res5.status == 404) {
                self.setState({
                  isMedicalBioCompleted: false
                })
              }
            })
          })
        }
      })
    }
  }
  onLogin(res, redirectPath) {
    let self = this
    let userId = res.data.data.id
    let tokenizedStr = utils.genTokenizedString()
    if (tokenizedStr) {
      account.fetchUser(tokenizedStr, userId).then(function(res) {
        if (res.status == 200) {
          let user = res.data.data;
          self.setState({
            isConsented: true,
            isLoggedIn: true, 
            isPoR: user.role === 5 ? true : false, 
            isFullyMounted: true,
            user: user
          })
          utils.setCookie('cookieConsentGiven', 'true', 365);
          if (redirectPath) {
            if (Object.prototype.toString.call(redirectPath) == "[object Object]") {
              self.props.history.push(redirectPath.pathname);
            } else {
              window.location.href = redirectPath;
            }
          }
          return;
        }
        if (res.status == 502 || res.status == 404) {
          self.onLogout();
        }
      }).then(function() {
        self.setState({
          isFetchingShippingAddress: true
        }, function() {
          addresses.fetchShippingAddress(tokenizedStr).then(function(res2) {
            if (res2.status == 200) {
              let shippingAddress = res2.data.data;
              self.setState({
                shippingAddress: shippingAddress,
                isFetchingShippingAddress: false
              })
            } else {
              if (res2.status !== 20) {
                self.setState({
                  isFetchingShippingAddress: false
                })
              }
            }
            return;
          })
        })
      }).then(function() {
        self.setState({
          isFetchingStripeCustomer: true
        }, function() {
          billing.fetchStripeCustomer(tokenizedStr).then(function(res3) {
            if (res3.status == 200) {
              let stripeCustomer = res3.data.data;
              self.setState({
                stripeBillingMethods: stripeCustomer.billingMethods,
                stripeCustomerId: stripeCustomer.stripe_customerId,
                isFetchingStripeCustomer: false
              });
            } else {
              if (res3.status !== 20) {
                self.setState({
                  isFetchingStripeCustomer: false
                })
              }
            }
          })
        })
      }).then(function() {
        self.setState({
          isFetchingMedicalBios: true
        }, function() {
          bios.fetchMedicalBios(tokenizedStr).then(function(res4) {
            if (res4.status == 200) {
              self.setState({
                medicalBios: res4.data.data,
                isFetchingMedicalBios: false
              });
            } else {
              if (res4.status !== 20) {
                self.setState({
                  isFetchingMedicalBios: false
                })
              }
            }
          })
        })
      }).then(function() {
        self.setState({
          isFetchingOrders: true
        }, function() {
          self.fetchOrders();
        })
      })
    }
  }
  onLogout() {
    utils.deleteCookie('token');
    this.setState({
      isLoggedIn: false,
      isFullyMounted: true
    })
  }
  onUpdateUser(user) {
    this.setState({
      user: user
    })
  }
  onUpdateShippingAddress(shippingAddress, callback) {
    this.setState({
      shippingAddress: shippingAddress
    }, callback)
  }
  onUpdateStripeBillingMethods(stripeBillingMethods, callback) {
    this.setState({
      stripeBillingMethods: stripeBillingMethods
    }, callback)
  }
  render() {
    return (
      <Elements stripe={stripePromise}>

      {this.state.isFullyMounted && <Menu location={this.props.location} user={this.state.user} isLoggedIn={this.state.isLoggedIn} onLogout={this.onLogout}/>}

        <Switch>
          <Route 
            path="/home"
            exact
            render={(props) => (
              this.state.isFullyMounted && <Home {...props}
                user={this.state.user}
                isLoggedIn={this.state.isLoggedIn}
                showModal={this.showModal}
                onUpdateUser={this.onUpdateUser}
                onConsent={this.onConsent} />
            )}
          />

          <Route 
            path="/checkout"
            exact
            render={(props) => (
              this.state.isFullyMounted && <Checkout {...props}
                user={this.state.user}
                isLoggedIn={this.state.isLoggedIn}
                showModal={this.showModal}
                hideModal={this.hideModal}
                onUpdateUser={this.onUpdateUser}
                onConsent={this.onConsent} />
            )}
          />

          <Route 
            path="/complete-profile"
            exact
            render={(props) => (
              this.state.isFullyMounted && <CompleteProfile {...props}
                user={this.state.user}
                isLoggedIn={this.state.isLoggedIn}
                showModal={this.showModal}
                hideModal={this.hideModal}
                onUpdateUser={this.onUpdateUser}
                onConsent={this.onConsent} />
            )}
          />

          <Route 
            path="/complete-bio"
            exact
            render={(props) => (
              this.state.isFullyMounted && <CompleteBio {...props} 
                hideModal={this.hideModal}
              />
            )}
          />

          /*
          <Route 
            path="/shop"
            exact
            render={(props) => (
              this.state.isFullyMounted && <Shop {...props}
                user={this.state.user}
                stripeCustomerId={this.state.stripeCustomerId}
                stripeBillingMethods={this.state.stripeBillingMethods}
                shippingAddress={this.state.shippingAddress}
                isLoggedIn={this.state.isLoggedIn}
                showModal={this.showModal}
                onUpdateUser={this.onUpdateUser}
                onLogout={this.onLogout}
                onOptOut={this.onOptOut}
                isFetchingStripeCustomer={this.state.isFetchingStripeCustomer} 
                onPurchase={this.fetchOrders}/>
            )}
          />
          */

          <Route 
            path="/orders"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                this.state.isFullyMounted && <Orders {...props}
                  user={this.state.user}
                  orders={this.state.orders}
                  invoiceItems={this.state.invoiceItems}
                  shipments={this.state.shipments}
                  isFetchingOrders={this.state.isFetchingOrders}
                  isLoggedIn={this.state.isLoggedIn}
                  onUpdateUser={this.onUpdateUser}
                  onLogout={this.onLogout}
                  onOptOut={this.onOptOut} />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route 
            path="/activate"
            exact
            render={(props) => (
              <Activate {...props}
                user={this.state.user}
                orders={this.state.orders}
                isFetchingOrders={this.state.isFetchingOrders}
                isLoggedIn={this.state.isLoggedIn}
                onUpdateUser={this.onUpdateUser}
                onLogout={this.onLogout}
                onOptOut={this.onOptOut}
                onActivate={this.fetchOrders}
              />
            )}
          />

          <Route 
            path="/thank-you"
            exact
            render={(props) => (
              <ThankYou {...props}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />

          <Route 
            path="/"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                this.state.isFullyMounted && <MyAdyn {...props} 
                  user={this.state.user}
                  isFetchingOrders={this.state.isFetchingOrders}
                  isFetchingShippingAddress={this.state.isFetchingShippingAddress}
                  isFetchingShipments={this.state.isFetchingShipments}
                  isFetchingStripeCustomer={this.state.isFetchingStripeCustomer}
                  onUpdateUser={this.onUpdateUser}
                  onUpdateShippingAddress={this.onUpdateShippingAddress}
                  onUpdateStripeBillingMethods={this.onUpdateStripeBillingMethods}
                  shippingAddress={this.state.shippingAddress}
                  medicalBios={this.state.medicalBios}
                  stripeBillingMethods={this.state.stripeBillingMethods}
                  orders={this.state.orders}
                  invoiceItems={this.state.invoiceItems}
                  surveys={this.state.surveys}
                  telemedicineVisits={this.state.telemedicineVisits}
                  isMedicalBioStarted={this.state.isMedicalBioStarted}
                  isMedicalBioCompleted={this.state.isMedicalBioCompleted}
                  />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route 
            path="/por"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                this.state.isFullyMounted && <PoR {...props} 
                  user={this.state.user}
                  isPoR={this.state.isPoR}
                  showModal={this.showModal}
                  />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route 
            path="/support"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                this.state.isFullyMounted && <Support {...props} 
                  user={this.state.user}
                  />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route 
            path="/schedule"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                this.state.isFullyMounted && <Schedule {...props} 
                  user={this.state.user}
                  />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route 
            path="/medical-bio"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                this.state.isFullyMounted && <MedicalBio {...props} 
                  user={this.state.user}
                  isMedicalBioCompleted={this.state.isMedicalBioCompleted}
                  />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route
            path="/signup"
            exact
            render={(props) => (
              <Signup {...props} onConsent={this.onConsent} />
            )}
          />

          <Route
            path="/login"
            exact
            render={(props) => (
              <LogIn {...props} isLoggedIn={this.state.isLoggedIn} onLogin={this.onLogin} onLogout={this.onLogout} onConsent={this.onConsent}/>
            )}
          />

          <Route
            path="/logout"
            exact
            render={(props) => (
              this.state.isLoggedIn ? (
                <LogOut {...props} 
                  onLogout={this.onLogout}
                  />
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
              )
            )}
          />

          <Route
            path="/verify"
            exact
            render={(props) => (<Verify {...props}  onLogout={this.onLogout}/>)}
          />

          <Route
            path="/request-reset"
            exact
            render={(props) => (<RequestReset {...props}  onLogout={this.onLogout}/>)}
          />

          <Route
            path="/reset"
            exact
            render={(props) => (<Reset {...props}  onLogout={this.onLogout}/>)}
          />

          <Route path="*">
            <FourZeroFour/>
          </Route>

        </Switch>

        {this.state.isFullyMounted && <Footr location={this.props.location} />}
        {this.state.showModal && <Modal hideModal={this.hideModal} title={this.state.modalTitle} message={this.state.modalMessage} confirmMessage={this.state.modalConfirmMessage} cancelMessage={this.state.modalCancelMessage} onConfirm={this.state.modalCallback}/>}
      </Elements>
    );
  }
}

Main.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

const WithRouterMain = withRouter(Main)
ReactDOM.render(<Router><WithRouterMain /></Router>, document.getElementById('root'));
