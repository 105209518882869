import React from 'react';
import PropTypes from 'prop-types';
import StripeCheckout from 'react-stripe-checkout';
import billing from '../js/billing';
import utils from '../js/utils';
import {STRIPE_PK} from '../js/keys';

const CURRENCY = 'USD';
const StripeCheckoutButton = ({ name, amount, action, email, callback, disabled }) => {
  const publishableKey = STRIPE_PK;
  let description = '';
  let label = '';
  switch(action) {
    case 'CREATE_CUSTOMER':
      description = 'Please enter your billing information';
      label = 'Add Credit Card';
    break;
    case 'ATTACH_SOURCE':
      description = 'Please enter your billing information';
      label = 'Add Credit Card';
    break;
    case 'MAKE_PAYMENT':
      description = `Your total is $${amount / 100}`;
      label = 'Make Payment';
    break;
  }

  const onToken = token => {
    let tokenizedStr = utils.genTokenizedString();
    if (tokenizedStr) {
      switch(action) {
        case 'CREATE_CUSTOMER':
          billing.createCustomer(tokenizedStr, {
            billing_method: token.id
          }).then(function(res) {
            callback(res);
          })
        break;
        case 'ATTACH_SOURCE':
          billing.attachBillingMethod(tokenizedStr, {
            billing_method: token.id
          }).then(function(res) {
            callback(res);
          })
        break;
        case 'MAKE_PAYMENT':
          callback(token, description, CURRENCY, amount)
        break;
      }
    } else {
      alert('Token missing or expired. Please log in again.')
    }
  };

  return (
    <StripeCheckout
      label={label}
      name={name}
      email={email}
      billingAddress
      shippingAddress={false}
      image='/img/adyn_social_media_icon.jpg'
      description={description}
      amount={amount}
      currency={CURRENCY}
      panelLabel='Submit'
      token={onToken}
      stripeKey={publishableKey}
      disabled={disabled}
    >
      <button type="button" className="link align-left">{label}</button>
    </StripeCheckout>
  )
}

export default StripeCheckoutButton;

StripeCheckoutButton.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.number,
  action: PropTypes.string,
  email: PropTypes.string,
  callback: PropTypes.func,
  disabled: PropTypes.bool
}
