import React from 'react';

export default class Home extends React.Component {
  constructor(){
    super();
  }
  render(){
    return (
    <section className="black-bg white-text fullscreen home-hero">
      <div className="section-image object-cover">
				<img className="lazyautosizes lazyloaded" data-sizes="auto" alt="Four young women casually smiling at camera" data-src="/img/medium/home-hero.jpg" data-srcset="/img/small/home-hero.jpg 480w, /img/medium/home-hero.jpg 720w, /img/large/home-hero.jpg 1440w, /img/fullscreen/home-hero.jpg 2880w" sizes="1554px" srcset="/img/small/home-hero.jpg 480w, /img/medium/home-hero.jpg 720w, /img/large/home-hero.jpg 1440w, /img/fullscreen/home-hero.jpg 2880w" src="/img/medium/home-hero.jpg" />
      </div>
    </section>
    )
  }
}

