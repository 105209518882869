import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import equal from 'fast-deep-equal';
import ProgressBar from '@ramonak/react-progress-bar';
import Sidebar from '../components/Sidebar';
import utils from '../js/utils';
import account from '../js/account';
import billing from '../js/billing';
import addresses from '../js/addresses';
import mailer from '../js/mailer';
import auth from '../js/auth';
import {STRIPE_PK} from '../js/keys';

import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeCardForm from './StripeCardForm';

const stripePromise = loadStripe(STRIPE_PK);


export default class MyAdyn extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email: utils.replaceNullString(props.user.email),
      newEmail: utils.replaceNullString(props.user.newEmail),
      phone: utils.replaceNullPhone(props.user.phone),
      firstName: utils.replaceNullString(props.user.firstName),
      lastName: utils.replaceNullString(props.user.lastName),
      preferredName: utils.replaceNullString(props.user.preferredName),
      sex: utils.replaceNullString(props.user.sex),
      gender: (props.user.gender !== 'female' && props.user.gender !== 'male' && props.user.gender !== 'non-binary' && props.user.gender !== '' && props.user.gender !== null) ? 'other' : utils.replaceNullString(props.user.gender),
      otherGender: (props.user.gender !== 'female' && props.user.gender !== 'male' && props.user.gender !== 'non-binary' && props.user.gender !== '' && props.user.gender !== null) ? utils.replaceNullString(props.user.gender) : '',
      pronouns: (props.user.pronouns !== 'he/him' && props.user.pronouns !== 'she/her' && props.user.pronouns !== 'they/them' && props.user.pronouns !== '' && props.user.pronouns) ? 'other' : utils.replaceNullString(props.user.pronouns),
      otherPronouns: (props.user.pronouns !== 'he/him' && props.user.pronouns !== 'she/her' && props.user.pronouns !== 'they/them' && props.user.pronouns) ? utils.replaceNullString(props.user.pronouns) : '',
      dateOfBirth: utils.replaceNullUndefined(props.user.dateOfBirth),
      stripeCustomerId: utils.replaceNullString(props.user.stripeCustomerId),
      user: props.user,
      pendingNewEmail: false,
      isUpdatingProfile: false,
      isOrderExpanded: false,

      shippingFirstName: utils.replaceNullString(props.shippingAddress.firstName),
      shippingLastName: utils.replaceNullString(props.shippingAddress.lastName),
      shippingAddress1: utils.replaceNullString(props.shippingAddress.address1),
      shippingAddress2: utils.replaceNullString(props.shippingAddress.address2),
      shippingCity: utils.replaceNullString(props.shippingAddress.city),
      shippingState: utils.replaceNullString(props.shippingAddress.state),
      shippingCountry: utils.replaceNullString(props.shippingAddress.country),
      shippingZip: utils.replaceNullString(props.shippingAddress.zip),
      shippingDeliveryInstructions: utils.replaceNullString(props.shippingAddress.deliveryInstructions),
      shippingAddress: props.shippingAddress,

      hasShippingAddress: true,
      hasBillingMethod: true,
      userCompletion: true,
      profileCompletion: 0,
      medicalBioCompletion: 100,
      medicalBioPart1Completion: true,
      medicalBioPart2Completion: true,
      perceptionSurveyCompletion: 0,
      perceptionSurvey1Status: '',
      perceptionSurvey2Status: '',
      perceptionSurvey3Status: '',
      orderCompletion: 0,
      hasOrdered: true,
      hasActivated: true,
      isReadyForActivation: true,
      hasTakenDna: true,
      hasTakenHormone: true,
      hasSentDnaToLab: true,
      hasSentHormoneToLab: true,

      updateAlert: '',
      shippingAlert: '',
      billingAlert: '',
      dobAlert: '',
      zipAlert: ''
    }

    this.changeStage = this.changeStage.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNewEmailChange = this.onNewEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onPreferredNameChange = this.onPreferredNameChange.bind(this);
    this.onSexChange = this.onSexChange.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
    this.onOtherGenderChange = this.onOtherGenderChange.bind(this);
    this.onPronounsChange = this.onPronounsChange.bind(this);
    this.onOtherPronounsChange = this.onOtherPronounsChange.bind(this);
    this.onDateOfBirthChange = this.onDateOfBirthChange.bind(this);

    this.onShippingFirstNameChange = this.onShippingFirstNameChange.bind(this);
    this.onShippingLastNameChange = this.onShippingLastNameChange.bind(this);
    this.onShippingAddress1Change = this.onShippingAddress1Change.bind(this);
    this.onShippingAddress2Change = this.onShippingAddress2Change.bind(this);
    this.onShippingCityChange = this.onShippingCityChange.bind(this);
    this.onShippingStateChange = this.onShippingStateChange.bind(this);
    this.onShippingCountryChange = this.onShippingCountryChange.bind(this);
    this.onShippingZipChange = this.onShippingZipChange.bind(this);
    this.onShippingDeliveryInstructionsChange = this.onShippingDeliveryInstructionsChange.bind(this);

    this.isShippingStateValid = this.isShippingStateValid.bind(this);

    this.onCustomerCreated = this.onCustomerCreated.bind(this);
    this.onCardToken = this.onCardToken.bind(this);
    this.onAlertTextUpdate = this.onAlertTextUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onStripeBillingMethodAdded = this.onStripeBillingMethodAdded.bind(this);
    this.processProfilePayload = this.processProfilePayload.bind(this);
    this.processShippingAddressPayload = this.processShippingAddressPayload.bind(this);
    this.resendVerificationEmail = this.resendVerificationEmail.bind(this);

    this.renderOrderStatus = this.renderOrderStatus.bind(this);
    this.renderPerceptionSurveyStatus = this.renderPerceptionSurveyStatus.bind(this);
    this.renderTelemedicineVisitStatus = this.renderTelemedicineVisitStatus.bind(this);
    this.deleteStripeBillingMethod = this.deleteStripeBillingMethod.bind(this);

    this.formatSex = this.formatSex.bind(this);
    this.formatGender = this.formatGender.bind(this);
    this.formatPronouns = this.formatPronouns.bind(this);
    this.formatGreetingName = this.formatGreetingName.bind(this);

    this.formatBCTActivationClassName = this.formatBCTActivationClassName.bind(this);
    this.formatBCTActivationStatusText = this.formatBCTActivationStatusText.bind(this);
    this.formatBCTActivationStatusIcon = this.formatBCTActivationStatusIcon.bind(this);

    this.formatOrderStatus = this.formatOrderStatus.bind(this);

    this.toggleProfileUpdating = this.toggleProfileUpdating.bind(this);

    this.toggleExpandOrders = this.toggleExpandOrders.bind(this);
  }
  changeStage(stage) {
    this.scrollToTargetAdjusted('section-' + stage);
  }
  scrollToTargetAdjusted(elId){
    window.setTimeout(() => {
      window.scrollTo({
        top: document.getElementById(elId).offsetTop - 100,
        behavior: "smooth"
      });
    }, 200);
  }
  onEmailChange(evt) {
    this.setState({email: evt.target.value});
  }
  onNewEmailChange(evt) {
    this.setState({newEmail: evt.target.value});
  }
  onPhoneChange(evt) {
    this.setState({phone: utils.validatePhoneNumber(evt.target.value)})
  }
  onFirstNameChange(evt) {
    this.setState({firstName: evt.target.value});
  }
  onLastNameChange(evt) {
    this.setState({lastName: evt.target.value});
  }
  onPreferredNameChange(evt) {
    this.setState({preferredName: evt.target.value});
  }
  onSexChange(evt) {
    this.setState({sex: evt.target.value})
  }
  onGenderChange(evt) {
    var gender = evt.target.value;
    var newState = {
      gender: evt.target.value
    };
    if (gender === 'female' || gender === 'male' || gender === 'non-binary') {
      newState['otherGender'] = '';
    }
    this.setState(newState);
  }
  onOtherGenderChange(evt) {
    this.setState({otherGender: evt.target.value})
  }
  onPronounsChange(evt) {
    var pronouns = evt.target.value;
    var newState = {
      pronouns: evt.target.value
    };
    if (pronouns === 'he/him' || pronouns === 'she/her' || pronouns === 'non-binary') {
      newState['otherPronouns'] = '';
    }
    this.setState(newState);
  }
  onOtherPronounsChange(evt) {
    this.setState({otherPronouns: evt.target.value})
  }
  onDateOfBirthChange(evt) {
    var newState = {
      dateOfBirth: evt.target.value
    }
    if (!utils.isDateOfBirthValid(evt.target.value)) {
      newState['dobAlert'] = 'Must be 18 or older';
    } else {
      newState['dobAlert'] = '';
    }
    this.setState(newState);
  }
  onShippingFirstNameChange(evt) {
    this.setState({shippingFirstName: evt.target.value})
  }
  onShippingLastNameChange(evt) {
    this.setState({shippingLastName: evt.target.value})
  }
  onShippingAddress1Change(evt) {
    this.setState({shippingAddress1: evt.target.value})
  }
  onShippingAddress2Change(evt) {
    this.setState({shippingAddress2: evt.target.value})
  }
  onShippingCityChange(evt) {
    this.setState({shippingCity: evt.target.value})
  }
  isShippingStateValid() {
    return this.state.shippingState !== 'NY' && this.state.shippingState !== 'NJ' && this.state.shippingState !== 'RI';
  }
  onShippingStateChange(evt) {
    this.setState({shippingState: evt.target.value})
  }
  onShippingCountryChange(evt) {
    this.setState({shippingCountry: evt.target.value})
  }
  onShippingZipChange(evt) {
    var zip = utils.validateZip(evt.target.value)
    var newState = {
      shippingZip: zip
    }
    if (!utils.isZipValid(zip)) {
      newState['zipAlert'] = 'Invalid zip'
    } else {
      newState['zipAlert'] = '';
    }
    this.setState(newState);
  }
  onShippingDeliveryInstructionsChange(evt) {
    this.setState({shippingDeliveryInstructions: evt.target.value})
  }
  formatBCTActivationClassName() {
    if (this.state.hasActivated) {
      return 'tile complete'
    } else {
      if (this.state.isReadyForActivation) {
        return 'tile action-needed'
      } else {
        return 'tile not-available'
      }
    }
  }
  formatBCTActivationStatusText() {
    if (this.state.hasActivated) {
      return 'Complete'
    } else {
      if (this.state.isReadyForActivation) {
        return 'Action needed'
      } else {
        return 'Not yet available'
      }
    }
  }
  formatBCTActivationStatusIcon() {
    if (this.state.hasActivated) {
      return '✔'
    } else {
      if (this.state.isReadyForActivation) {
        return '!'
      } else {
        return '⦸'
      }
    }
  }
  formatOrderStatus(status) {
    var statuses = {
      'reserved': 'Reserved',
      'allied reserved': 'Preparing',
      'allied processing': 'Packaging',
      'allied cancelled': 'Cancelled',
      'left allied facility': 'Delivering',
      'notice left': 'Notice left',
      'held': 'Held',
      'delivered': 'Delivered',
      'resolved': 'Resolved'
    }

    return statuses[status];
  }
  calcCompletion() {
    var hasShippingAddress = Object.keys(this.props.shippingAddress).length > 0 ? true : false;
    var hasBillingMethod = this.props.stripeBillingMethods.length > 0 ? true : false;

    var profileCompletion = 0;
    profileCompletion = hasShippingAddress ? profileCompletion + 15 : profileCompletion;
    profileCompletion = this.props.user.email ? profileCompletion + 15 : profileCompletion;
    profileCompletion = this.props.user.firstName ? profileCompletion + 10 : profileCompletion;
    profileCompletion = this.props.user.lastName ? profileCompletion + 10 : profileCompletion;
    profileCompletion = this.props.user.phone && this.props.user.phone !== '(555) 555 - 5555' ? profileCompletion + 15 : profileCompletion;
    profileCompletion = this.props.user.sex ? profileCompletion + 15 : profileCompletion;
    profileCompletion = this.props.user.dateOfBirth ? profileCompletion + 10 : profileCompletion;

    var userCompletion = this.props.user.firstName && this.props.user.lastName && (this.props.user.phone && this.props.user.phone !== '(555) 555 - 5555') && this.props.user.sex && this.props.user.dateOfBirth ? true : false;

    var perceptionSurveyCompletion = 0;
    for (var i = 0, len = this.props.surveys; i < len; i++) {
      perceptionSurveyCompletion = this.props.surveys[i].status === 'completed' ? perceptionSurveyCompletion + 33 : perceptionSurveyCompletion;
    }

    var medicalBioPart1Completion = false;
    var medicalBioPart2Completion = false;
    var medicalBioCompletion = 0;
    for (i = 0, len = this.props.medicalBios.length; i < len; i++) {
      let mb = this.props.medicalBios[i];
      if (mb.jotform_form_id === '211891546201350') {
        medicalBioPart1Completion = true;
        medicalBioCompletion += 50;
      }

      if (mb.jotform_form_id === '211893745251358') {
        medicalBioPart2Completion = true;
        medicalBioCompletion += 50;
      }
    }

    var orderCompletion = 0;
    var hasOrdered = this.props.invoiceItems.length > 0 || this.props.orders.length > 0 ? true : false;
    var hasActivated = this.props.orders.length > 0 && this.props.orders[0].activated ? true : false;
    var isReadyForActivation = this.props.orders.length > 0 && this.props.orders[0].status === 'customer delivered' ? true : false;

    this.setState({
      hasShippingAddress: hasShippingAddress,
      hasBillingMethod: hasBillingMethod,
      userCompletion: userCompletion,
      profileCompletion: profileCompletion,
      perceptionSurveyCompletion: perceptionSurveyCompletion,
      medicalBioCompletion: medicalBioCompletion,
      medicalBioPart1Completion: medicalBioPart1Completion,
      medicalBioPart2Completion: medicalBioPart2Completion,
      hasOrdered: hasOrdered,
      hasActivated: hasActivated,
      isReadyForActivation: isReadyForActivation
    })
  }
  componentDidMount() {
    this.calcCompletion();
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.user, prevProps.user) || !equal(this.props.shippingAddress, prevProps.shippingAddress) || !equal(this.props.stripeBillingMethods, prevProps.stripeBillingMethods) || !equal(this.props.shippingAddress, prevProps.shippingAddress) || !equal(this.props.orders, prevProps.orders) || !equal(this.props.telemedicineVisits, prevProps.telemedicineVisits) || !equal(this.props.surveys, prevProps.surveys) || !(equal(this.props.medicalBios, prevProps.medicalBios) || !(equal(this.props.isMedicalBioStarted, prevProps.isMedicalBioStarted) || !(equal(this.props.isMedicalBioCompleted, prevProps.isMedicalBioCompleted))))) {
      this.calcCompletion();
    }

    if (!equal(this.props.user, prevProps.user)) {
      let user = this.props.user
      this.setState({
        email: utils.replaceNullString(user.email),
        newEmail: utils.replaceNullString(user.newEmail),
        phone: utils.replaceNullPhone(user.phone),
        firstName: utils.replaceNullString(user.firstName),
        lastName: utils.replaceNullString(user.lastName),
        preferredName: utils.replaceNullString(user.preferredName),
        sex: utils.replaceNullString(user.sex),
        gender: (user.gender !== 'female' && user.gender !== 'male' && user.gender !== 'non-binary' && user.gender !== '') ? 'other' : utils.replaceNullString(user.gender),
        pronouns: (user.pronouns !== 'he/him' && user.pronouns !== 'she/her' && user.pronouns !== 'they/them' && user.pronouns !== '') ? 'other' : utils.replaceNullString(user.pronouns),
        emailPref: user.emailPref !== undefined ? user.emailPref : '',
        stripeCustomerId: user.stripeCustomerId !== undefined ? user.stripeCustomerId : '',
        user: user
      });
    }

    if (!equal(this.props.shippingAddress, prevProps.shippingAddress)) {
      let shippingAddress = this.props.shippingAddress
      this.setState({
        shippingFirstName: utils.replaceNullString(shippingAddress.firstName),
        shippingLastName: utils.replaceNullString(shippingAddress.lastName),
        shippingAddress1: utils.replaceNullString(shippingAddress.address1),
        shippingAddress2: utils.replaceNullString(shippingAddress.address2),
        shippingCity: utils.replaceNullString(shippingAddress.city),
        shippingState: utils.replaceNullString(shippingAddress.state),
        shippingCountry: utils.replaceNullString(shippingAddress.country),
        shippingZip: utils.replaceNullString(shippingAddress.zip),
        shippingDeliveryInstructions: utils.replaceNullString(shippingAddress.deliveryInstructions),
        shippingAddress: shippingAddress
      });
    }
  }
  renderOrderStatus() {
    if (this.props.orders.length > 0) {
      var recentOrder = this.props.orders[0];
      switch(recentOrder.status) {
        case 'reserved':
          return 'Your birth control test has been ordered';
        case 'delivering':
          return 'Your birth control test is on the way!';
        default:
          return 'Order your birth control test';
      }
    } else {
      return 'Order your birth control test';
    }
  }
  renderTelemedicineVisitStatus() {
    if (this.props.telemedicineVisits.length > 0) {
      var recentTelemedicineVisit = this.props.telemedicineVisits[0];
      switch(resentTelemedicineVisit.status) {
        case 'not scheduled':
          return 'Schedule your telemedicine visit';
        case 'scheduled':
          return 'Your telemedicine visit has been scheduled';
        case 'completed':
          return 'Your telemedicine visit has been completed';
        default:
          return 'Schedule your telemedicine visit';
      }
    } else {
      return 'Schedule your telemedicine visit';
    }
  }
  renderPerceptionSurveyStatus(stage) {
    if (this.props.surveys.length > stage) {
      var status = this.props.surveys[stage].status ;
      this.state['perceptionSurvey' + stage + 'Status'] = status;
      switch(status) {
        case 'not scheduled':
          return 'Schedule Perception Survey #' + stage;
        case 'scheduled':
          return 'Perception Survey #' + stage + ' scheduled';
        case 'completed':
          return 'Perception Survey #' + stage + ' completed';
        case 'opted out':
          return 'You have opted out from Perception Survey #' + stage;
        default:
          return 'Schedule Perception Survey #' + stage;
      }
    } else {
      return 'Schedule Perception Survey #' + stage;
    }
  }
  processProfilePayload() {
    let payload = {}
    let user = this.props.user
    if (this.state.phone !== user.phone) {
      payload['phone'] = this.state.phone;
    }
    if (this.state.firstName !== user.firstName) {
      payload['first_name'] = this.state.firstName;
    }
    if (this.state.lastName !== user.lastName) {
      payload['last_name'] = this.state.lastName;
    }
    if (this.state.preferredName !== user.preferredName) {
      payload['preferred_name'] = this.state.preferredName;
    }
    if (this.state.sex !== user.sex) {
      payload['sex'] = this.state.sex;
    }
    if (this.state.otherGender !== this.state.gender) {
      payload['gender'] = this.state.otherGender;
    }
    if (this.state.gender !== user.gender) {
      payload['gender'] = this.state.gender;
    }

    if (this.state.otherPronouns !== this.state.pronouns) {
      payload['pronouns'] = this.state.otherPronouns;
    }
    if (this.state.pronouns !== user.pronouns) {
      payload['pronouns'] = this.state.pronouns;
    }

    if ((this.state.dateOfBirth !== user.dateOfBirth) && (this.state.dateOfBirth !== undefined && this.state.dateOfBirth !== null)) {
      payload['date_of_birth'] = this.state.dateOfBirth;
    }
    if (this.state.newEmail !== '') {
      payload['new_email'] = this.state.newEmail;
    }

    return payload;
  }
  processShippingAddressPayload() {
    let payload = {}
    let shippingAddress = this.props.shippingAddress
    let changedFlag = false;

    if (this.state.shippingFirstName !== utils.replaceNullString(shippingAddress.firstName)) {
      changedFlag = true;
    }

    if (this.state.shippingLastName !== utils.replaceNullString(shippingAddress.lastName)) {
      changedFlag = true;
    }

    if (this.state.shippingAddress1 !== utils.replaceNullString(shippingAddress.address1)) {
      changedFlag = true;
    }

    if (this.state.shippingAddress2 !== utils.replaceNullString(shippingAddress.address2)) {
      changedFlag = true;
    }

    if (this.state.shippingCity !== utils.replaceNullString(shippingAddress.city)) {
      changedFlag = true;
    }

    if (this.state.shippingState !== utils.replaceNullString(shippingAddress.state)) {
      changedFlag = true;
    }

    if (this.state.shippingZip !== utils.replaceNullString(shippingAddress.zip)) {
      changedFlag = true;
    }

    if (this.state.shippingCountry !== utils.replaceNullString(shippingAddress.country)) {
      changedFlag = true;
    }

    if (changedFlag) {
      payload['first_name'] = this.state.shippingFirstName;
      payload['last_name'] = this.state.shippingLastName;
      payload['address1'] = this.state.shippingAddress1;
      payload['address2'] = this.state.shippingAddress2;
      payload['city'] = this.state.shippingCity;
      payload['state'] = this.state.shippingState;
      payload['zip'] = this.state.shippingZip;
      payload['country'] = this.state.shippingCountry;
    }
    return payload;
  }
  resendVerificationEmail() {
    let self = this;
    let tokenizedStr = utils.genTokenizedString();
    if (tokenizedStr) {
      self.setState({
        updateAlert: 'Resending verification email...',
        shippingAlert: '',
        addShippingAlert: '',
        billingAlert: '',
        otpAlert: ''
      })
      account.reVerifyUser(tokenizedStr, this.props.user.id, {
        email: this.props.user.email,
        new_email: this.state.newEmail
      }).then(function(res) {
        if (res.status == 200) {
          mailer.sendEmail({
            template: 'verification_update',
            recipient: self.props.user.email,
            email: self.state.newEmail,
            app: 'portal',
            path: '/verify?u_code=' + res.data.data.u_code + '&email=' + encodeURIComponent(self.state.newEmail)
          }).then(function(res2) {
            if (res2.status == 200) {
              self.setState({
                updateAlert: 'New email pending verification. Please check your ' + self.props.user.email + ' inbox to continue the registration process.'
              })
            } else {
              self.setState({
                updateAlert: res2.statusText
              })
            }
          });
        } else {
          if (res.status !== 20) {
            self.setState({
              updateAlert: res.statusText
            })
          }
        }
      })
    }
  }
  onCustomerCreated(res) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      billingAlert: 'Updating user details...',
      otpAlert: ''
    });

    if (res.status === 200) {
      let stripeCustomer = res.data.data;
      let tokenizedStr = utils.genTokenizedString();
      account.updateUser(tokenizedStr, this.props.user.id, {'stripe_customer_id': stripeCustomer.stripe_customer_id}).then(function(res2) {
        if (res2.status === 200) {
          self.props.onUpdateStripeBillingMethods(stripeCustomer.billing_methods, function() {
            self.setState({
              billingAlert: 'Payment details updated successfully'
            });
          })
          auth.refresh(tokenizedStr);
        } else {
          if (res2.status != 20) {
            billing.deleteCustomer(tokenizedStr, stripeCustomer.customer_id).then(function() {
              self.setState({
                billingAlert: 'There was an issue adding your payment method. Please try again.'
              });
            })
          }
        }
      });
    } else {
      if (res.status !== 20) {
        self.setState({
          billingAlert: res.statusText
        })
      }
    }
  }
  onCardToken(token) {
    let self = this;
    let tokenizedStr = utils.genTokenizedString();
    if (tokenizedStr) {
      billing.attachBillingMethod(tokenizedStr, {
        billing_method: token.id
      }).then(function(res) {
        self.onStripeBillingMethodAdded(res);
      })
    } else {
      alert('Token missing or expired. Please log in again.')
    }
  }
  onStripeBillingMethodAdded(res) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      billingAlert: '',
      otpAlert: ''
    })
    if (res.status == 200) {
      let stripeBillingMethods = [...this.props.stripeBillingMethods];
      stripeBillingMethods.push(res.data.data);
      self.props.onUpdateStripeBillingMethods(stripeBillingMethods, function() {
        self.setState({
          billingAlert: res.statusText
        })
      })
    } else {
      if (res.status !== 20) {
        self.setState({
          billingAlert: res.statusText
        })
      }
    }
  }
  deleteStripeBillingMethod(id) {
    let self = this;
    this.setState({
      updateAlert: '',
      shippingAlert: '',
      billingAlert: 'Deleting payment method...'
    })
    let tokenizedStr = utils.genTokenizedString();
    billing.deleteStripeBillingMethod(tokenizedStr, id).then(function(res) {
      if (res.status == 200) {
        let stripeBillingMethods = [...self.props.stripeBillingMethods]
        utils.removeArrayItemById(stripeBillingMethods, id);
        self.props.onUpdateStripeBillingMethods(stripeBillingMethods, function() {
          self.setState({
            billingAlert: res.statusText
          })
        })
      } else {
        if (res.status !== 20) {
          self.setState({
            billingAlert: res.statusText
          })
        }
      }
    })
  }
  formatSex() {
    var sexes = {
      '': '',
      'male': 'Male',
      'female': 'Female'
    }

    return sexes[this.state.sex];
  }
  formatGender() {
    var genders = {
      '': '',
      'male': 'Male',
      'female': 'Female',
      'non-binary': 'Non-binary',
      'other': 'Other'
    }

    return this.state.otherGender ? this.state.otherGender.charAt(0).toUpperCase() + this.state.otherGender.slice(1) : genders[this.state.gender];
  }
  formatPronouns() {
    var pronouns = {
      'he/him': 'He/Him',
      'she/her': 'She/Her',
      'they/them': 'They/Them',
      'other': 'Other'
    }

    return this.state.otherPronouns ? this.state.otherPronouns.charAt(0).toUpperCase() + this.state.otherPronouns.slice(1) : pronouns[this.state.pronouns];
  }
  onAlertTextUpdate(text) {
    this.setState({
      billingAlert: text
    });
  }
  onSubmit(evt) {
    evt.preventDefault();
    let profilePayload = this.processProfilePayload();
    let shippingAddressPayload = this.processShippingAddressPayload();

    this.setState({
      updateAlert: utils.isEmptyObject(profilePayload) && utils.isEmptyObject(shippingAddressPayload) ? 'No changes to profile were detected' : '',
      shippingAlert: '',
      billingAlert: '',
      otpAlert: ''
    })

    if (!utils.isEmptyObject(profilePayload)) {
      let self = this;
      let tokenizedStr = utils.genTokenizedString();
      if (tokenizedStr) {
        self.setState({
          updateAlert: 'Updating profile...',
        })
        account.updateUser(tokenizedStr, this.props.user.id, profilePayload).then(function(res) {
          if (res.status == 200) {
            let modifiedFields = utils.formatUnderscoredFields(profilePayload);
            let updatedStateUser = utils.updateObjWithObj(self.state.user, modifiedFields);
            self.props.onUpdateUser(updatedStateUser);
            let newState = {
              updateAlert: res.statusText
            }
            if ('new_email' in profilePayload) {
              newState.pendingNewEmail = true;
              mailer.sendEmail({
                template: 'verification_update',
                recipient: res.data.data.old_email,
                email: res.data.data.new_email,
                app: 'portal',
                path: '/verify?u_code=' + res.data.data.u_code + '&email=' + encodeURIComponent(res.data.data.new_email)
              }).then(function(res2) {
                if (res2.status == 200) {
                  self.setState({
                    updateAlert: 'New email pending verification. Please check your ' + res.data.data.old_email + ' inbox to continue the registration process.'
                  })
                } else {
                  self.setState({
                    updateAlert: res2.statusText
                  })
                }
              });
            }
            self.setState(newState)
          } else {
            if (res.status !== 20) {
              self.setState({
                updateAlert: res.statusText
              })
            }
          }
        })
      }
    }

    if (!utils.isEmptyObject(shippingAddressPayload)) {
      let self = this;
      let tokenizedStr = utils.genTokenizedString();
      if (tokenizedStr) {
        self.setState({
          updateAlert: 'Updating shipping address...',
        })
        addresses.addShippingAddress(tokenizedStr, shippingAddressPayload).then(function(res) {
          if (res.status == 200) {
            let modifiedFields = utils.formatUnderscoredFields(shippingAddressPayload);
            let updatedStateShippingAddress = utils.updateObjWithObj(self.state.shippingAddress, modifiedFields);
            self.props.onUpdateShippingAddress(updatedStateShippingAddress);
            let newState = {
              updateAlert: res.statusText
            }
            self.setState(newState);
          } else {
            if (res.status !== 20) {
              self.setState({
                updateAlert: res.statusText
              })
            }
          }
        })
      }
    }
  }
  toggleProfileUpdating() {
    this.setState({
      isUpdatingProfile: !this.state.isUpdatingProfile
    });
  }
  toggleExpandOrders(index) {
    this.setState({
      isOrderExpanded: !this.state.isOrderExpanded
    });
  }
  formatGreetingName() {
    if (this.props.user.preferredName !== '') {
      return this.props.user.preferredName;
    } else {
      return this.props.user.firstName !== '' ? this.props.user.firstName : this.props.user.email;
    }
  }
  render(){
    var self = this;
    return (
      <div>
        <section className="white-bg green-text fullscreen dashboard-hero">
          <div className="section-image object-cover">
            <img className="lazyautosizes lazyloaded" data-sizes="auto" alt="Green pattern" data-src="/img/medium/.jpg" data-srcset="/img/small/pink-hexagons.png 480w, /img/medium/pink-hexagons.png 720w, /img/large/pink-hexagons.png 1440w, /img/fullscreen/pink-hexagons.png 2880w" sizes="1554px" srcSet="/img/small/pink-hexagons.png 480w, /img/medium/pink-hexagons.png 720w, /img/large/pink-hexagons.png 1440w, /img/fullscreen/pink-hexagons.png 2880w" src="/img/medium/pink-hexagons.png" />
          </div>
          <div className="wrap intro-text">
            <h2 className="widont balance-text">Hello {this.formatGreetingName()}, <br data-owner="balance-text" />welcome to the next chapter of <br data-owner="balance-text" />your Birth Control Odyssey</h2>
            <div className="notification-panel">
              <h4>Your Notifications</h4>
              <ul>
                {!this.state.userCompletion && <li>Complete your <button type="button" className="link" onClick={() => {this.changeStage('profile')}}>profile</button></li>}
                {!this.props.isMedicalBioCompleted && <li>{this.props.isMedicalBioStarted ? 'Complete' : 'Create'} your <Link to="/medical-bio">medical biography</Link></li>}
                {this.state.medicalBioCompletion > 0 && this.state.medicalBioCompletion < 100 && <li>Complete your <Link to="/medical-bio">medical biography</Link></li>}
                {!this.props.isFetchingShippingAddress && this.state.hasShippingAddress === false && <li>Add a <button type="button" className="link" onClick={() => {this.changeStage('profile')}}>shipping address</button></li>}
                {!this.props.isFetchingOrders && this.state.hasOrdered === false && <li>Order your <button type="button" className="link" onClick={() => {this.changeStage('orders')}}>The Birth Control Test</button></li>}
                {!this.props.isFetchingOrders && this.state.hasActivated === false && this.state.isReadyForActivation === true && <li><Link to="/activate">Activate</Link> your kit</li>}
              </ul>
            </div>
          </div>
        </section>

        <section className="marine-bg green-text fullscreen profile-hero" id="section-journey">
          <div className="wrap">
            <div className="label">
              <h4>Your Journey</h4>
            </div>
            <ul className="tiles">
              <HashLink to="/#profile">
                <li className={this.state.userCompletion ? 'tile complete' : 'tile action-needed'}>
                  <h5>Step 1</h5>
                  <p>Complete your Profile</p>
                  <div className="tile-status">
                    <div className="tile-status-text">{this.state.userCompletion ? 'Completed' : 'Action needed'}</div>
                    <div className="tile-icon">{this.state.userCompletion ? '✔' : '!'}</div>
                  </div>
                </li> 
              </HashLink>
              <Link to="/medical-bio">
                <li className={this.props.isMedicalBioCompleted ? 'tile complete' : 'tile action-needed'}>
                  <h5>Step 2</h5>
                  <p>{this.props.isMedicalBioStarted ? 'Complete' : 'Create'} your Medical Biography</p>
                  <div className="tile-status">
                    <div className="tile-status-text">{this.props.isMedicalBioCompleted ? 'Completed' : 'Action needed'}</div>
                    <div className="tile-icon">{this.props.isMedicalBioCompleted ? '✔' : '!'}</div>
                  </div>
                </li> 
              </Link>
              <Link to="/activate">
                <li className={this.formatBCTActivationClassName()}>
                  <h5>Step 3</h5>
                  <p>Activate your Birth Control Test</p>
                  <div className="tile-status">
                    <div className="tile-status-text">{this.formatBCTActivationStatusText()}</div>
                    <div className="tile-icon">{this.formatBCTActivationStatusIcon()}</div>
                  </div>
                </li> 
              </Link>
              <li className="tile not-available">
                <h5>Step 4</h5>
                <p>Collect your samples and ship to the lab</p>
                <div className="tile-status">
                  <div className="tile-status-text">Not yet available</div>
                  <div className="tile-icon">&#10680;</div>
                </div>
              </li> 
              <li className="tile not-available">
                <h5>Step 5</h5>
                <p>Review your Results</p>
                <div className="tile-status">
                  <div className="tile-status-text">Not yet available</div>
                  <div className="tile-icon">&#10680;</div>
                </div>
              </li> 
              <li className="tile not-available">
                <h5>Step 6</h5>
                <p>Schedule your Telemedicine visit</p>
                <div className="tile-status">
                  <div className="tile-status-text">Not yet available</div>
                  <div className="tile-icon">&#10680;</div>
                </div>
              </li> 
              <li className="tile not-available">
                <h5>Step 7</h5>
                <p>Fill or refill your prescription</p>
                <div className="tile-status">
                  <div className="tile-status-text">Not yet available</div>
                  <div className="tile-icon">&#10680;</div>
                </div>
              </li> 
            </ul>
          </div>
        </section>
        <section className="cream-bg green-text fullscreen order-hero" id="section-orders">
          <div className="wrap">
            <div className="label">
              <h4>Order History</h4>
            </div>
            <div className="container">
              {this.props.orders.length > 0 && this.props.orders.map((o, i) => {
                  return (
                    <div key={i} className="split order">
                      <div className="split-text">
                        <p><b>{o.name}</b></p>
                      </div>
                      <div className="split-text">
                        <p>
                          <span>{this.formatOrderStatus(o.status)}</span><span className="expand" onClick={this.toggleExpandOrders}>{this.state.isOrderExpanded ? 'x' : '+'}</span>
                        </p>
                        {this.state.isOrderExpanded &&
                          <p>
                            Order ID: {o.friendly_order_id}<br/>
                            Date order placed: {o.date_order_placed}<br/>
                            Date order paid: {o.date_order_paid}</p>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </section>

        <a name="profile" id="profile"></a>
        <section className="seafoam-bg green-text fullscreen profile-hero" id="section-profile">
          <div className="wrap">
            <div className="label">
              <h4>Personal Information</h4>
            </div>
            <div className="container">
              <div className="split">
                <div className="split-text">
                  <div className="block">
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Name</p>
                      </div>
                      <div className="split-text">
                        <div className="input-wrap">
                          <p>{this.state.firstName} {this.state.lastName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Preferred name</p>
                      </div>
                      <div className="split-text">
                        <div className="input-wrap">
                          <p>{this.state.preferredName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Phone</p>
                      </div>
                      <div className="split-text">
                        <div className="input-wrap">
                          <p>{this.state.phone}</p>
                        </div>
                      </div>
                    </div>
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Date of birth</p>
                      </div>
                      <div className="split-text">
                        <div className="input-wrap">
                          <p>{this.state.dateOfBirth}</p>
                        </div>
                      </div>
                    </div>
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Sex assigned at birth</p>
                      </div>
                      <div className="split-text">
                        <div className="input-wrap">
                          <p>{this.formatSex()}</p>
                        </div>
                      </div>
                    </div>
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Gender</p>
                      </div>
                      <div className="split-text">
                        <p>{this.formatGender()}</p>
                      </div>
                    </div>
                    <div className="split pb-2">
                      <div className="split-text">
                        <p className="attribute">Pronouns</p>
                      </div>
                      <div className="split-text">
                        <p>{this.formatPronouns()}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="split-text">

                  <div className="block account">
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Email Address</p>
                      </div>
                      <div className="split-text">
                        <p>{this.state.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="block">
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">Shipping Address</p>
                      </div>
                      <div className="split-text">
                        <div className="split">
                          <div className="split-text pb-2">
                            <div className="input-wrap">
                              <p>{this.state.shippingFirstName} {this.state.shippingLastName}<br/>
                              {this.state.shippingAddress1}<br/>
                              {this.state.shippingAddress2 && <span>{this.state.shippingAddress2}<br/></span>}
                              {this.state.shippingCity ? this.state.shippingCity + ', ' : ''}{this.state.shippingState} {this.state.shippingZip}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="block">
                    <div className="split">
                      <div className="split-text">
                        <p className="attribute">{this.props.stripeBillingMethods.length > 1 ? 'Payment methods' : 'Payment method'}</p>
                      </div>
                      <div className="split-text">
                        {this.props.stripeBillingMethods.length > 0 && this.props.stripeBillingMethods.map((s, i) => {
                          return (
                            <fieldset className="input-wrap credit-card" key={i}>{s.brand} ending in {s.last4} expiring on {s.expires}</fieldset>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <fieldset className="button-wrap">
              <input type="button" className="button" value="Update Profile" onClick={this.toggleProfileUpdating}/>
            </fieldset>
          </div>
        </section>

        {this.state.isUpdatingProfile &&
          <div className="section-profile-modal">
            <span className="section-profile-modal-close" onClick={this.toggleProfileUpdating}>&times;</span>
            <section className="alabaster-bg green-text fullscreen profile-hero section-profile-modal-content">
              <div className="wrap">
                <div className="label">
                  <h4>Edit Your Personal Information</h4>
                </div>
                <form id="profile-form" onSubmit={this.onSubmit}>
                  <div className="container">
                    <div className="split">
                      <div className="split-text">
                        <div className="block account">
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute"><b>Profile</b></p>
                            </div>
                            <div className="split-text">
                            </div>
                          </div>

                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">First name</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="first-name" id="profile-first-name" value={this.state.firstName} onChange={this.onFirstNameChange} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Last name</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="last-name" id="profile-last-name" value={this.state.lastName} onChange={this.onLastNameChange} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Preferred name</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="preferred-name" id="profile-preferred-name" value={this.state.preferredName} onChange={this.onPreferredNameChange} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Phone</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="phone" id="profile-phone" value={this.state.phone} onChange={this.onPhoneChange} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Date of birth</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="date" name="date-of-birth" id="profile-date-of-birth" value={this.state.dateOfBirth} onChange={this.onDateOfBirthChange} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Sex assigned at birth</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <select id="profile-sex" value={this.state.sex} onChange={this.onSexChange}> <option value=""></option>
                                  <option value="female">Female</option>
                                  <option value="male">Male</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Gender</p>
                            </div>
                            <div className="split-text">
                              {this.state.gender == 'other' ?
                                <div className="split">
                                  <div className="split-text">
                                    <label className="input-wrap">
                                      <select id="profile-gender" value={this.state.gender} onChange={this.onGenderChange}>
                                        <option value=""></option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                        <option value="non-binary">Non-binary</option>
                                        <option value="other">Other</option>
                                      </select>
                                    </label>
                                  </div>
                                  <div className="split-text">
                                    <label className="input-wrap">
                                      <input type="text" name="other-gender" id="profile-other-gender" value={this.state.otherGender} onChange={this.onOtherGenderChange} />
                                    </label>
                                  </div>
                                </div>
                              :
                                <select id="profile-gender" value={this.state.gender} onChange={this.onGenderChange}>
                                  <option value=""></option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                    <option value="non-binary">Non-binary</option>
                                    <option value="other">Other</option>
                                </select>
                              }
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Pronouns</p>
                            </div>
                            <div className="split-text">
                              {this.state.pronouns == 'other' ?
                                <div className="split">
                                  <div className="split-text">
                                    <label className="input-wrap">
                                      <select id="profile-pronouns" value={this.state.pronouns} onChange={this.onPronounsChange}>
                                        <option value=""></option>
                                        <option value="he/him">He/Him</option>
                                        <option value="she/her">She/Her</option>
                                        <option value="they/them">They/Them</option>
                                        <option value="other">Other</option>
                                      </select>
                                    </label>
                                  </div>
                                  <div className="split-text">
                                    <label className="input-wrap">
                                      <input type="text" name="other-pronouns" id="profile-other-pronouns" value={this.state.otherPronouns} onChange={this.onOtherPronounsChange} />
                                    </label>
                                  </div>
                                </div>
                              :
                                <select id="profile-pronouns" value={this.state.pronouns} onChange={this.onPronounsChange}>
                                  <option value=""></option>
                                  <option value="he/him">He/Him</option>
                                  <option value="she/her">She/Her</option>
                                  <option value="they/them">They/Them</option>
                                  <option value="other">Other</option>
                                </select>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="block account">
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Email Address</p>
                            </div>
                            <div className="split-text">
                              <fieldset className="input-wrap">
                                <input type="email" name="email" id="profile-email" value={this.state.email} onChange={this.onEmailChange} />
                              </fieldset>
                            </div>
                          </div>
                          {this.props.user.newEmail &&
                            <div className="split">
                              <div className="split-text">
                                <p className="attribute">Pending Email Address</p>
                              </div>
                              <div className="split-text">
                                <fieldset className="input-wrap">
                                  <input type="email" name="email" id="profile-email" value={this.state.newEmail} onChange={this.onNewEmailChange} />
                                </fieldset>
                              </div>
                            </div>
                          }
                          <div className="split">
                            <div className="split-text">
                            </div>
                            <div className="split-text">
                              { (this.state.newEmail || this.state.pendingNewEmail) && <fieldset className="input-wrap"><button type="button" className="link align-left" onClick={this.resendVerificationEmail}>Resend verification email</button></fieldset>}
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Password</p>
                            </div>
                            <div className="split-text">
                              <fieldset className="input-wrap">
                                <Link to="/request-reset" className="align-left">Change/Update password</Link>
                              </fieldset>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="split-text">
                        <div className="block account">

                          <div className="split">
                            <div className="split-text">
                              <p className="attribute"><b>Shipping Address</b></p>
                            </div>
                            <div className="split-text">
                            </div>
                          </div>

                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Name</p>
                            </div>
                            <div className="split-text">
                              <div className="split">
                                <div className="split-text">
                                  <div className="input-wrap">
                                    <input type="text" name="shipping-first-name" id="profile-shipping-first-name" value={this.state.shippingFirstName} onChange={this.onShippingFirstNameChange} />
                                  </div>
                                </div>
                                <div className="split-text">
                                  <div className="input-wrap">
                                    <input type="text" name="shipping-last-name" id="profile-shipping-last_name" value={this.state.shippingLastName} onChange={this.onShippingLastNameChange} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Address1</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="shipping-address1" id="profile-shipping-address1" value={this.state.shippingAddress1} onChange={this.onShippingAddress1Change} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">Address2</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="shipping-address2" id="profile-shipping-address2" value={this.state.shippingAddress2} onChange={this.onShippingAddress2Change} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">City</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <input type="text" name="shipping-city" id="profile-shipping-city" value={this.state.shippingCity} onChange={this.onShippingCityChange} />
                              </div>
                            </div>
                          </div>
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute">State, Zip</p>
                            </div>
                            <div className="split-text">
                              <div className="split">
                                <div className="split-text">
                                  <div className="input-wrap">
                                    <select id="shipping-state" value={this.state.shippingState} onChange={this.onShippingStateChange} >
                                      <option value=""></option>
                                      <option value="AL">Alabama</option>
                                      <option value="AK">Alaska</option>
                                      <option value="AZ">Arizona</option>
                                      <option value="AR">Arkansas</option>
                                      <option value="CA">California</option>
                                      <option value="CO">Colorado</option>
                                      <option value="CT">Connecticut</option>
                                      <option value="DE">Delaware</option>
                                      <option value="DC">District Of Columbia</option>
                                      <option value="FL">Florida</option>
                                      <option value="GA">Georgia</option>
                                      <option value="HI">Hawaii</option>
                                      <option value="ID">Idaho</option>
                                      <option value="IL">Illinois</option>
                                      <option value="IN">Indiana</option>
                                      <option value="IA">Iowa</option>
                                      <option value="KS">Kansas</option>
                                      <option value="KY">Kentucky</option>
                                      <option value="LA">Louisiana</option>
                                      <option value="ME">Maine</option>
                                      <option value="MD">Maryland</option>
                                      <option value="MA">Massachusetts</option>
                                      <option value="MI">Michigan</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MS">Mississippi</option>
                                      <option value="MO">Missouri</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="NV">Nevada</option>
                                      <option value="NH">New Hampshire</option>
                                      <option value="NM">New Mexico</option>
                                      <option value="NJ">New Jersey</option>
                                      <option value="NY">New York</option>
                                      <option value="NC">North Carolina</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="OH">Ohio</option>
                                      <option value="OK">Oklahoma</option>
                                      <option value="OR">Oregon</option>
                                      <option value="PA">Pennsylvania</option>
                                      <option value="RI">Rhode Island</option>
                                      <option value="SC">South Carolina</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="TN">Tennessee</option>
                                      <option value="TX">Texas</option>
                                      <option value="UT">Utah</option>
                                      <option value="VT">Vermont</option>
                                      <option value="VA">Virginia</option>
                                      <option value="WA">Washington</option>
                                      <option value="WV">West Virginia</option>
                                      <option value="WI">Wisconsin</option>
                                      <option value="WY">Wyoming</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="split-text">
                                  <div className="input-wrap">
                                    <input type="text" name="shipping-zip" id="profile-shipping-zip" value={this.state.shippingZip} onChange={this.onShippingZipChange} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="split pb-2">
                            <div className="split-text">
                              <p className="attribute">Country</p>
                            </div>
                            <div className="split-text">
                              <div className="input-wrap">
                                <select id="shipping-country" value={this.state.shippingCountry} onChange={this.onShippingCountryChange} >
                                  <option value=""></option>
                                  <option value="US">United States</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                    <fieldset className="input-wrap">
                      {!this.isShippingStateValid() && <p className="warning">We currently cannot ship to NY, NJ, or RI. Visit our <a href="https://www.adyn.com/faq" target="_blank">FAQ</a> to find out why. When we begin expanding our supported states, we'll reach out to you by email.</p>}

                    </fieldset>

                    <fieldset className="input-wrap">
                      {this.state.zipAlert !== '' && <div className="alert info">{this.state.zipAlert}</div>}
                    </fieldset>
                    <fieldset className="input-wrap">
                      {this.state.updateAlert !== '' && <div className="alert info">{this.state.updateAlert}</div>}
                    </fieldset>
                    <fieldset className="button-wrap pb-2">
                      <input type="submit" className="button" id="profile-update" value="Update Profile"/>&nbsp;&nbsp;&nbsp;<input type="button" className="button" value="Close" onClick={this.toggleProfileUpdating}/>
                    </fieldset>

                    <div className="split">
                      <div className="split-text">
                        <div className="block">
                          <div className="split">
                            <div className="split-text">
                              <p className="attribute"><b>{this.props.stripeBillingMethods.length > 1 ? 'Payment methods' : 'Payment method'}</b></p>
                            </div>
                            <div className="split-text">
                            </div>
                          </div>
                          <div className="pb-2">
                            {this.props.stripeBillingMethods.length > 0 && this.props.stripeBillingMethods.map((s, i) => {
                              return (
                                <div className="split" key={i}>
                                  <fieldset className="input-wrap credit-card">{s.brand} ending in {s.last4} expiring on {s.expires} <button type="button" className="link align-left" onClick={() => { this.deleteStripeBillingMethod(s.id) }}>Delete</button></fieldset>
                                </div>
                              )
                            })}
                          </div>
                          <p className="pb-1">Add Credit Card</p>
                          <div className="split">
                            <Elements stripe={stripePromise}>
                              <ElementsConsumer>
                                {({stripe, elements}) => (
                                  <StripeCardForm
                                    firstName={self.state.shippingFirstName}
                                    lastName={self.state.shippingLastName}
                                    address1={self.state.shippingAddress1}
                                    address2={self.state.shippingAddress2}
                                    city={self.state.shippingCity}
                                    state={self.state.shippingState}
                                    zip={self.state.shippingZip}
                                    country={self.state.shippingCountry}
                                    disabled={false}
                                    stripe={stripe}
                                    elements={elements}
                                    customerId={self.props.user.id}
                                    onSubmit={self.onCardToken}
                                    alertText={self.state.billingAlert}
                                    onAlertTextUpdate={self.onAlertTextUpdate}
                                  />
                                )}
                              </ElementsConsumer>
                            </Elements>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="split">
                      <div className="split-text">
                      </div>
                    </div>

                    <div className="split">
                      <div className="split-text">
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </section>
          </div>
        }
      </div>
    )
  }
}

MyAdyn.propTypes = {
  user: PropTypes.object,
  isFetchingOrders: PropTypes.bool,
  isFetchingShippingAddress: PropTypes.bool,
  isFetchingShipments: PropTypes.bool,
  isFetchingStripeCustomer: PropTypes.bool,
  onUpdateUser: PropTypes.func,
  onUpdateShippingAddress: PropTypes.func,
  shippingAddress: PropTypes.object,
  stripeBillingMethods: PropTypes.array,
  orders: PropTypes.array,
  invoiceItems: PropTypes.array,
  telemedicineVisits: PropTypes.array,
  surveys: PropTypes.array,
  medicalBios: PropTypes.array,
  location: PropTypes.object,
  onUpdateStripeBillingMethods: PropTypes.func,
  isMedicalBioStarted: PropTypes.bool,
  isMedicalBioCompleted: PropTypes.bool
}
