import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import utils from '../js/utils';
import shop from '../js/shop';
import Panel from './Panel';
import Sidebar from '../components/Sidebar';

export default class Shop extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      prices: [],
      error: '',
      success: '',
      info: '',
      isRequesting: false,
      loginPrompt: false,
      billingPrompt: false,
      tokenPrompt: false
    }
  }
  componentDidMount() {
    let self = this;
    shop.fetchStripePrices().then(function(res) {
      if (res.status == 200) {
        self.setState({
          prices: res.data.data.reverse()
        })
      }
    })
  }
  render(){
    return (
      <div>
        {this.props.isLoggedIn && <Sidebar location={this.props.location} /> }
        <div className={this.props.isLoggedIn ? "content" : ""}>
          <div className="container">
            <div className="page">
              <div className="card card-xl">
                <div className="card-body">
                  {!this.props.isFetchingStripeCustomer && this.state.prices.length > 0 && this.state.prices.filter(x => x['category'] === 'kit').map((p) => {
                    return (<Panel
                      key={p['id']}
                      id={p['id']}
                      name={p['name']}
                      amount={p['amount']}
                      description={p['description']}
                      features={p['features'].split('|')}
                      url={p['url']}
                      image={p['image']}
                      slogan={p['slogan']}
                      fullPrice={p['full_price']}
                      shippingDetails={p['shipping_details']}
                      offers={p['offers']}
                      isRequesting={this.state.isRequesting}
                      showModal={this.props.showModal}
                      hideModal={this.props.hideModal}
                      isLoggedIn={this.props.isLoggedIn}
                      stripeBillingMethods={this.props.stripeBillingMethods}
                      stripeCustomerId={this.props.stripeCustomerId}
                      shippingAddress={this.props.shippingAddress}
                      user={this.props.user}
                      onPurchase={this.props.onPurchase}/>)
                  })} 
                  {this.props.isFetchingStripeCustomer ?
                  <div className="col col-md">
                    <div className="panel">
                      {this.state.info !== '' && <div className="alert info">{this.state.info}</div>}
                      {this.state.success !== '' && <div className="alert success">{this.state.success}</div>}
                      {this.state.error !== '' && <div className="alert danger">{this.state.error}</div>}
                    </div>
                  </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Shop.propTypes = {
  user: PropTypes.object,
  stripeCustomerId: PropTypes.string,
  stripeBillingMethods: PropTypes.array,
  shippingAddress: PropTypes.object,
  isFetchingStripeCustomer: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  onUpdateUser: PropTypes.func,
  location: PropTypes.object,
  onPurchase: PropTypes.func
}
