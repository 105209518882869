import {TOKEN_PATH} from './paths';
import utils from './utils';
import axios from 'axios';

export default {
  name: 'auth',
  login(username, password) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: TOKEN_PATH,
      method: 'GET',
      withCredentials: true,
      auth: {
        username: username,
        password: password
      },
      headers: {
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  refresh(token) {
    utils.handleOutgoingRequest(this);
    return axios({
      url: TOKEN_PATH + '/refresh',
      method: 'GET',
      withCredentials: true,
      auth: {
        username: token
      },
      headers: {
        'Accept': 'application/json'
      },
      cancelToken: this.cts.token
    }).then(res => { return utils.handleResponse(this, res) })
    .catch(err => { return utils.handleRequestError(this, err)})
  },
  abort() {
    if (this.active) {
      this.cts.cancel();
      this.cts = axios.CancelToken.source() ;
    }
  },
  active: false,
  cts: axios.CancelToken.source()
}
