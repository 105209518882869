import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Sidebar from '../components/Sidebar';
import JotformEmbed from 'react-jotform-embed';
import jotform from '../js/jotform';
import utils from '../js/utils';
import { MEDICAL_BIOS, MEDICAL_BIO_PARTS, MEDICAL_BIO_DURATIONS } from '../js/constants';

export default class MedicalBio extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      parameters: {}
    }
  }
  componentDidMount() {
    let self = this;
    let userId = this.props.user.id
    let tokenizedStr = utils.genTokenizedString()
    if (tokenizedStr && this.props.user.jotformFormId === MEDICAL_BIOS.TWO) {
      jotform.fetchSubmission(tokenizedStr, MEDICAL_BIOS.ONE).then(function(res) {
        if (res.status == 200) {
          let submission = res.data.data;
          self.setState({
            parameters: JSON.parse(submission.parameters)
          })
        } else {
          console.log('error')
        }
      })
    }
  }
  render(){
    return (
      <div>
        <section className="seafoam-bg green-text fullscreen dashboard-hero">
          <div className="wrap green-text">
            <h2 className="widont balance-text align-left">Your Medical Biography</h2>
            {this.props.isMedicalBioCompleted ?
              <div>
                <h3 className="align-left">Thank you for completing your medical bio.</h3>
              </div>
            :
              <div>
                {this.props.user.jotformFormId &&
                  <div>
                    <h3 className="align-left">Part {MEDICAL_BIO_PARTS[this.props.user.jotformFormId]} of 4</h3>
                    <p className="widont align-left">This section normally takes around {MEDICAL_BIO_DURATIONS[this.props.user.jotformFormId]} minutes.</p>
                  </div>
                }
              </div>
            }

          </div>
        </section>

        {!this.props.isMedicalBioCompleted &&
          <section className="seafoam-bg green-text fullscreen dashboard-hero">
            <div className="wrap intro-text">
              {this.props.user.jotformFormId === MEDICAL_BIOS.TWO ?
                <div>
                  {this.state.parameters &&
                    <JotformEmbed src={"https://form.jotform.com/" + this.props.user.jotformFormId + "?customerId=" + this.props.user.id + "&pill=" + this.state.parameters.pastpill + "&patch=" + this.state.parameters.pastpatch + "&ring=" + this.state.parameters.pastring + "&shot=" + this.state.parameters.pastshot + "&implant=" + this.state.parameters.pastimplant + "&iud=" + this.state.parameters.pastiud + "&ciud=" + this.state.parameters.pastciud + "&nojump"}/>
                  }
                </div>
              :
                <JotformEmbed src={"https://form.jotform.com/" + this.props.user.jotformFormId + "?customerId=" + this.props.user.id + "&nojump"} />
              }
            </div>
          </section>
        }
      </div>
    )
  }
}

MedicalBio.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  isMedicalBioCompleted: PropTypes.bool
}
