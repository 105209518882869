import React from 'react';
import PropTypes from 'prop-types';
import orders from '../js/orders';
import shipments from '../js/shipments';
import utils from '../js/utils';
import equal from 'fast-deep-equal';
import Sidebar from '../components/Sidebar';

export default class Orders extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      activationCode: '',
      parsedOrders: []
    }

    this.onActivationCodeChange = this.onActivationCodeChange.bind(this);
    this.parseOrders = this.parseOrders.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
  }
  onActivationCodeChange(evt) {
    this.setState({activationCode: evt.target.value});
  }
  componentDidMount() {
    this.parseOrders(this.props.orders, this.props.invoiceItems, this.props.shipments);
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.orders, prevProps.orders) || !equal(this.props.invoiceItems, prevProps.invoiceItems) || !equal(this.props.shipments, prevProps.shipments)) {
      this.parseOrders(this.props.orders, this.props.invoiceItems, this.props.shipments);
    }
  }
  parseOrders(orders, invoiceItems, shipments) {
    var parsedOrders = [];
    for (var i = 0, len = invoiceItems.length; i < len; i++) {
      var sit = invoiceItems[i];
      if (orders.length > 0) {
        for (var j = 0, jLen = orders.length; j < jLen; j++) {
          var o = orders[j];
          if (sit.id === o.stripe_invoice_item_id) {
            var parsedOrder = {
              orderId: o.id,
              activationCode: o.activation_code,
              stripeInvoiceItemId: sit.id,
              amount: sit.amount / 100,
              purchaseDate: sit.purchase_date,
              distributorOrderNumber: o.allied_order_number,
              dateOrderPaid: o.date_order_paid,
              dateOrderPlaced: o.date_order_placed,
              sku: o.sku,
              status: o.status,
              shipments: []
            }
          }
          for (var k = 0, kLen = shipments.length; k < kLen; k++) {
            var s = shipments[k];
            if (o.id === s.order_id) {
              parsedOrder.shipments.push({
                trackingNumber: s.tracking_number,
                dateShipped: s.date_shipped,
                shippedVia: s.shipped_via,
                status: s.status,
                details: s.details ? s.details.split('|') : [],
                returnTrackingNumber1: s.return_tracking_number_1,
                returnTrackingNumber2: s.return_tracking_number_2
              })
            }
          }
          parsedOrders.push(parsedOrder);
        }
      } else {
        for (var i = 0, len = invoiceItems.length; i < len; i++) {
          var sit = invoiceItems[i];
          parsedOrders.push({
            stripeInvoiceItemId: sit.id,
            amount: sit.amount / 100,
            purchaseDate: sit.purchase_date,
            status: 'purchased',
            shipments: []
          })
        }
      }
      this.setState({
        parsedOrders: parsedOrders
      })
    }
  }
  updateOrder(orderId) {
    let self = this;
    let tokenizedStr = utils.genTokenizedString();
    orders.updateOrder(tokenizedStr, orderId, {'activation_code': this.state.activationCode}).then(function(res) {
    });
  }
  render(){
    return (
      <div>
        <Sidebar location={this.props.location} />
        <div className="content">
          <div className="container">
            <div className="page profile">
              <div className="card card-lg">
                <div className="card-header">
                  <h1>My Orders</h1>
                </div>
                <div className="card-body">
                  {this.props.isFetchingOrders && <div className="alert info">Fetching order statuses...</div>}
                  {!this.props.isFetchingOrders && this.props.orders.length === 0 && this.props.invoiceItems.length > 0 &&
                    <p>Your order is currently being processed.</p>
                  }
                  {this.state.parsedOrders.length > 0 &&
                    this.state.parsedOrders.map((o, i) => {
                      return (
                        <div key={i} className="col col-md">
                          <h2>OrderID: {o.orderId}</h2>
                          <ul>
                            <li><b>Invoice Item ID:</b> {o.stripeInvoiceItemId}</li>
                            <li><b>Price:</b> ${o.amount}</li>
                            <li><b>Purchase Date:</b> {o.purchaseDate}</li>
                            <li><b>Distributor Order Number:</b> {o.distributorOrderNumber}</li>
                            <li><b>Date Order Paid:</b> {o.dateOrderPaid}</li>
                            <li><b>Date Order Placed:</b> {o.dateOrderPlaced}</li>
                            <li><b>SKU:</b> {o.sku}</li>
                            <li>Activation Code: {o.activationCode ? o.activationCode : "Your kit's activation code is currently being generated"}</li>
                            <li>
                              <b>Status:</b>
                              {o.shipments.length > 0 ?
                                <span className="alert info inline">deferred to postal service</span>
                              :
                                <span className="alert danger inline">{o.status}</span>
                              }
                            </li>
                            {o.shipments.length > 0 && <h3>Shipments</h3>}
                            {o.shipments.length > 0 &&
                              o.shipments.map((s, j) => {
                                return (
                                  <ul key={j}>
                                    <li><b>Tracking Number:</b> {s.trackingNumber}</li>
                                    <li><b>Date Shipped:</b> {s.dateShipped}</li>
                                    <li><b>Shipped Via:</b> {s.shippedVia}</li>
                                    <li><b>Status:</b> <span className={s.status === 'postal service delivered' ? "alert success inline" : "alert danger inline"}>{s.status}</span></li>
                                    <li><b>Shipping Details:</b> {s.details.length > 0 &&
                                      s.details.map((d, k) => {
                                        return (
                                          <ul key={k}>
                                            <li>{d}</li>
                                          </ul>
                                        )
                                      })
                                    }</li>
                                    <li><b>Return Tracking Number #1:</b> {s.returnTrackingNumber1}</li>
                                    <li><b>Return Tracking Number #2:</b> {s.returnTrackingNumber2}</li>
                                  </ul>
                                )
                              })
                            }
                          </ul>
                        </div>)
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Orders.propTypes = {
  user: PropTypes.object,
  isFetchingOrders: PropTypes.bool,
  orders: PropTypes.array,
  invoiceItems: PropTypes.array,
  shipments: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func
}
