import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import utils from '../js/utils';
import shop from '../js/shop';
import mailer from '../js/mailer'
import Panel from './Panel';
import account from '../js/account';
import addresses from '../js/addresses';
import billing from '../js/billing';
import {STRIPE_PK, STRIPE_PRICE_ID} from '../js/keys';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeBillingForm from './StripeBillingForm';

const stripePromise = loadStripe(STRIPE_PK);

export default class Checkout extends React.Component {
  constructor(props){
    super(props);
    const user = props.user !== undefined ? props.user : {};

    this.state = {
      stage: 'info',
      shippingFirstName: '',
      shippingLastName: '',
      shippingAddress1: '',
      shippingAddress2: '',
      shippingCity: '',
      shippingState: '',
      shippingCountry: 'US',
      shippingZip: '',
      shippingDeliveryInstructions: '',
      email: '',
      phone: '',
      password1: '',
      password2: '',
      isResidenceConfirmed: false,
      isAgeConfirmed: false,
      isTermsConfirmed: false,
      isAccountCreated: false,
      isPurchased: false,
      percentOff: 0,
      infoAlert: '',
      shippingAlert: '',
      termsAlert: '',
      accountAlert: '',
      passwordAlert: '',
      isPasswordValid: false,
      purchaseAlert: '',
      profileAlert: '',
      zipAlert: '',
      guestId: '',
      stripeBillingMethod: '',
      couponCode: ''
    }

    this.onShippingFirstNameChange = this.onShippingFirstNameChange.bind(this);
    this.onShippingLastNameChange = this.onShippingLastNameChange.bind(this);
    this.onShippingAddress1Change = this.onShippingAddress1Change.bind(this);
    this.onShippingAddress2Change = this.onShippingAddress2Change.bind(this);
    this.onShippingCityChange = this.onShippingCityChange.bind(this);
    this.onShippingStateChange = this.onShippingStateChange.bind(this);
    this.onShippingCountryChange = this.onShippingCountryChange.bind(this);
    this.onZipChange = this.onZipChange.bind(this);
    this.onShippingDeliveryInstructionsChange = this.onShippingDeliveryInstructionsChange.bind(this);

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);

    this.changeStage = this.changeStage.bind(this);
    this.onTermsConfirmedChange = this.onTermsConfirmedChange.bind(this);
    this.onAgeConfirmedChange = this.onAgeConfirmedChange.bind(this);
    this.onContactInfoSubmit = this.onContactInfoSubmit.bind(this);
    this.onShippingAddressSubmit = this.onShippingAddressSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.onPassword1Change = this.onPassword1Change.bind(this);
    this.onPassword2Change = this.onPassword2Change.bind(this);
    this.onTermsSubmit = this.onTermsSubmit.bind(this);
    this.onBillingSubmit = this.onBillingSubmit.bind(this);
    this.onPurchaseSubmit = this.onPurchaseSubmit.bind(this);
    this.onCouponFetched = this.onCouponFetched.bind(this);

    this.isShippingStateValid = this.isShippingStateValid.bind(this);
    this.isContactInfoCompleted = this.isContactInfoCompleted.bind(this);
    this.isShippingInfoCompleted = this.isShippingInfoCompleted.bind(this);
    this.isBillingInfoCompleted = this.isBillingInfoCompleted.bind(this);
    this.isTermsInfoCompleted = this.isTermsInfoCompleted.bind(this);
    this.isPasswordInfoCompleted = this.isPasswordInfoCompleted.bind(this);
  }
  onShippingFirstNameChange(evt) {
    this.setState({
      shippingFirstName: evt.target.value,
      firstName: evt.target.value
    })
  }
  onShippingLastNameChange(evt) {
    this.setState({
      shippingLastName: evt.target.value,
      lastName: evt.target.value
    })
  }
  onShippingAddress1Change(evt) {
    this.setState({shippingAddress1: evt.target.value})
  }
  onShippingAddress2Change(evt) {
    this.setState({shippingAddress2: evt.target.value})
  }
  onShippingCityChange(evt) {
    this.setState({shippingCity: evt.target.value})
  }
  onShippingStateChange(evt) {
    var self = this;
    this.setState({
      shippingState: evt.target.value
    }, () => {
      if (!self.isShippingStateValid()) {
        utils.trackGAEvent('invalidResidence');
        account.updateGuest(self.state.guestId, {
          residence_confirmed: false
        }).then(function(res) {
          if (res.status === 200) {
            console.log('Success: ' + res.statusText)
          } else {
            if (res.status != 20) {
              console.log('Error: ' + res.statusText)
            }
          }
        })
      }
    })
  }
  onShippingCountryChange(evt) {
    this.setState({shippingCountry: evt.target.value})
  }
  onZipChange(evt) {
    var zip = utils.validateZip(evt.target.value)
    var newState = {
      shippingZip: zip,
      zip: zip
    }
    if (!utils.isZipValid(zip)) {
      newState['zipAlert'] = 'Invalid zip'
    } else {
      newState['zipAlert'] = '';
    }
    this.setState(newState);
  }
  onShippingDeliveryInstructionsChange(evt) {
    this.setState({shippingDeliveryInstructions: evt.target.value})
  }
  onEmailChange(evt) {
    this.setState({email: evt.target.value})
  }
  onPhoneChange(evt) {
    this.setState({phone: utils.validatePhoneNumber(evt.target.value)})
  }
  onAgeConfirmedChange(evt) {
    this.setState({isAgeConfirmed: !this.state.isAgeConfirmed})
  }
  onTermsConfirmedChange(evt) {
    this.setState({isTermsConfirmed: !this.state.isTermsConfirmed})
  }
  handlePasswordChange() {
    var alert = '';
    var isPasswordValid = true;
    if (this.state.password1.length >= 8) {
      alert += '<li><span>&#10003;</span> Password must contain at least 8 characters</li>';
    } else {
      alert += '<li><span>X</span> Password must contain at least 8 characters</li>';
      isPasswordValid = false;
    }

    if (/[A-Z]/.test(this.state.password1)) {
      alert += '<li><span>&#10003;</span> Password must contain at least 1 uppercase letter</li>';
    } else {
      alert += '<li><span>X</span> Password must contain at least 1 uppercase letter</li>';
      isPasswordValid = false;
    }

    if (/\d/.test(this.state.password1)) {
      alert += '<li><span>&#10003;</span> Password must contain at least 1 digit</li>';
    } else {
      alert += '<li><span>X</span> Password must contain at least 1 digit</li>';
      isPasswordValid = false;
    }

    if (this.state.password1 === this.state.password2 && this.state.password2 !== '') {
      alert += '<li><span>&#10003;</span> Passwords must match</li>';
    } else {
      alert += '<li><span>X</span> Passwords must match</li>';
      isPasswordValid = false;
    }

    this.setState({
      passwordAlert: '<ul class="align-left">' + alert + '</ul>',
      isPasswordValid: isPasswordValid
    })
  }
  onPassword1Change(evt) {
    this.setState({password1: evt.target.value}, this.handlePasswordChange)
  }
  onPassword2Change(evt) {
    this.setState({password2: evt.target.value}, this.handlePasswordChange)
  }
  focusOn(stage) {
    var elements = {
      'info': 'email',
      'shipping': 'shipping-first-name',
      'billing': 'use-shipping',
      'review': 'age',
      'profile': 'profile-first-name'
    }
    document.getElementsByName(elements[stage])[0].focus(); 
  }
  changeStage(evt, stage, preventDefault) {
    var self = this;
    if (evt && preventDefault) {
      evt.preventDefault();
    }
    this.setState({
      infoAlert: '',
      shippingAlert: '',
      termsAlert: '',
      accountAlert: '',
      purchaseAlert: '',
      stage: stage
    }, () => {
      self.scrollToTargetAdjusted('tab-' + stage);
      self.focusOn(stage);
    });
  }
  scrollToTargetAdjusted(elId){
    window.setTimeout(() => {
      window.scrollTo({
        top: document.getElementById(elId).offsetTop + 300,
        behavior: "smooth"
      });
    }, 200);
  }
  onContactInfoSubmit(evt) {
    var self = this;
    evt.preventDefault();

    var infoAlertMessages = {
      'Submitting': '<div class="alert info">Submitting...</div>',
      'Success': '<div class="alert success">Thank you for providing your contact information!</div>',
      'Account exists': '<div class="alert danger">We noticed an account already exists with this email address. You now have the ability to <a href="/login">log in</a> for easy checkout. But please make sure to respond to the verification email, first!</div>',
      'Purchaser exists': '<div class="alert danger">We noticed that a purchase has already been made with this email address. Please <a href="/signup">sign up</a> with the same email address to manage this existing order and purchase new ones!</div>'
    }

    if (this.state.email !== '' && this.state.phone !== '') {
      this.setState({
        infoAlert: infoAlertMessages['Submitting'],
      })

      let payload = {
        email: this.state.email,
        phone: this.state.phone
      }

      account.createGuest(payload).then(function(res) {
        if (res.status == 200) {
          self.setState({
            infoAlert: '<div class="alert danger">Success</div>',
            guestId: res.data.data.id
          })
          utils.trackGAEvent('guestCreation');
          self.changeStage(evt, 'shipping');
        } else {
          if (res.statusText in infoAlertMessages) {
            self.setState({
              infoAlert: infoAlertMessages[res.statusText]
            })
          } else {
            self.setState({
              infoAlert: '<div class="alert danger">' + res.statusText + '</div>'
            })
          }
        }
      })
    } else {
      this.changeStage(evt, 'shipping');
    }
  }
  onShippingAddressSubmit(evt) {
    evt.preventDefault();
    var self = this;
    this.setState({
      shippingAlert: 'Adding shipping address...'
    })

    var payload = {
      'customer_id': this.state.guestId,
      'first_name': this.state.shippingFirstName,
      'last_name': this.state.shippingLastName,
      'address1': this.state.shippingAddress1,
      'address2': this.state.shippingAddress2,
      'city': this.state.shippingCity,
      'state': this.state.shippingState,
      'country': this.state.shippingCountry,
      'zip': this.state.shippingZip,
      'delivery_instructions': this.state.shippingDeliveryInstructions
    }

    addresses.addGuestShippingAddress(payload).then(function(res) {
      if (res.status == 200) {
        var isValidResident = self.isShippingStateValid();
        self.setState({
          shippingAlert: res.statusText,
          isResidenceConfirmed: isValidResident
        })

        self.props.onUpdateUser({
          firstName: self.state.shippingFirstName,
          lastName: self.state.shippingLastName
        })

        payload = {
          residence_confirmed: isValidResident,
          first_name: self.state.shippingFirstName,
          last_name: self.state.shippingLastName
        }

        account.updateGuest(self.state.guestId, payload).then(function(res2) {
          if (res2.status === 200) {
            console.log('Success: ' + res2.statusText)
          } else {
            if (res2.status != 20) {
              console.log('Error: ' + res2.statusText)
            }
          }
        })
        self.changeStage(evt, 'billing');
      } else {
        if (res.status !== 20) {
          self.setState({
            shippingAlert: res.statusText
          })
        }
      }
    })
  }
  onTermsSubmit(evt) {
    var self = this;
    evt.preventDefault();
    let payload = {
      residence_confirmed: this.state.isResidenceConfirmed,
      age_confirmed: this.state.isAgeConfirmed,
      terms_confirmed: this.state.isTermsConfirmed
    }
    account.updateGuest(this.state.guestId, payload).then(function(res) {
      if (res.status === 200) {
        self.setState({
          termsAlert: res.statusText
        });
      } else {
        if (res.status != 20) {
          self.setState({
            termsAlert: res.statusText
          });
        }
      }
    })
  }
  onCouponFetched(couponCode, percentOff) {
    this.setState({
      couponCode: couponCode,
      percentOff: percentOff
    })
  }
  onBillingSubmit(stripeBillingMethod) {
    this.setState({
      stripeBillingMethod: stripeBillingMethod
    });
    this.changeStage(undefined, 'review');
  }
  onPurchaseSubmit(evt) {
    var self = this;
    evt.preventDefault();

    this.setState({
      purchaseAlert: 'Processing payment...'
    });

    var payload = {
      residence_confirmed: this.state.isResidenceConfirmed,
      age_confirmed: this.state.isAgeConfirmed,
      terms_confirmed: this.state.isTermsConfirmed,
      email: this.state.email,
      customer_id: this.state.guestId
    }

    if (this.state.password1 !== '' && this.state.password2 !== '') {
      payload['password'] = this.state.password1;
    }

    if (this.state.couponCode) {
      payload['coupon_code'] = this.state.couponCode;
    }

    if (this.state.stripeBillingMethod) {
      payload['billing_method'] = this.state.stripeBillingMethod;
    }

    var billingFunction = 'guestPurchase';
    if (this.state.couponCode.length === 8 && !this.state.stripeBillingMethod) {
      billingFunction = 'zeroPurchase';
    }

    billing[billingFunction](STRIPE_PRICE_ID, payload).then(function(res) {
      if (res.status == 200) {
        var newState = {
          purchaseAlert: 'Purchase successful. Emailing receipt...',
          isPurchased: true
        }
        utils.setCookie('guestId', self.state.guestId);
        self.setState(newState);
        let purchaseDetails = res.data.data;
        payload = {
          template: 'purchase',
          recipient: self.state.email,
          subject: 'Purchase Receipt from Adyn',
          reference_id: purchaseDetails.reference_id,
          name: purchaseDetails.name,
          price: purchaseDetails.price,
          amount: purchaseDetails.amount
        }

        if (self.state.percentOff) {
          payload['coupon_code'] = self.state.couponCode;
          payload['discount'] = self.state.percentOff / 100;
          utils.trackGAEvent('couponRedemption', {
            'couponCode': self.state.couponCode,
            'percentOff': self.state.percentOff
          });
        }

        mailer.sendReceipt(payload).then(function(res2) {
          if (res2.status == 200) {
            self.setState({
              purchaseAlert: 'Email receipt was delivered to your inbox.',
            })
            utils.trackGAEvent('emailReceipt');
          } else {
            self.setState({
              purchaseAlert: res2.statusText,
            })
          }

          let payload = {
            residence_confirmed: self.state.isResidenceConfirmed,
            age_confirmed: self.state.isAgeConfirmed,
            terms_confirmed: self.state.isTermsConfirmed,
            stripe_customer_id: purchaseDetails.stripe_customer_id,
            role: '3'
          }

          account.updateGuest(self.state.guestId, payload).then(function(res3) {
            if (res3.status === 200) {

              var welcomeMessage = "<p>Keep an eye on your inbox for a receipt confirmation. Now, we just need some more information from you, in order to fulfill your order. Please click the button below or wait to be redirected in 15 seconds...</p>"
              var welcomeCallback = () => {
                self.props.history.push('/complete-profile');
              }
              var welcomeConfirmMessage = 'Complete My Profile';

              if (self.state.password1 !== '' && self.state.password2 !== '') {
                utils.trackGAEvent('guestAccountCreation');
                self.setState({
                  purchaseAlert: 'Creating your account...',
                })

                payload = {
                  email: self.state.email,
                  password: self.state.password1,
                  residence_confirmed: self.state.isResidenceConfirmed,
                  age_confirmed: self.state.isAgeConfirmed,
                  terms_confirmed: self.state.isTermsConfirmed,
                  phone: self.state.phone,
                  first_name: self.state.shippingFirstName,
                  last_name: self.state.shippingLastName
                }

                account.createUser(payload).then(function(res4) {
                  if (res4.status == 200) {
                    self.setState({
                      isAccountCreated: true,
                      purchaseAlert: 'Sending verification email...'
                    })
                    utils.setCookie('accountcreated', 'true', 1);
                    mailer.sendEmail({
                      template: 'verification_new',
                      recipient: res4.data.data.email,
                      email: res4.data.data.email,
                      app: 'portal',
                      path: '/verify?v_code=' + res4.data.data.v_code + '&email=' + encodeURIComponent(res4.data.data.email)
                    }).then(function(res5) {
                      if (res5.status == 200) {
                        self.setState({
                          purchaseAlert: 'Verification email sent'
                        })

                        if (self.state.password1 !== '' && self.state.password2 !== '') {
                          utils.setCookie('gueststatus', 'verify', 1);
                        } else {
                          utils.setCookie('gueststatus', 'signup', 1);
                        }

                        self.props.showModal('Thank you for your purchase!', welcomeMessage, welcomeCallback, welcomeConfirmMessage);
                        window.setTimeout(() => {
                          self.props.history.push('/complete-profile');
                          self.props.hideModal();
                        }, 15000)
                      } else {
                        if (res.status !== 20) {
                          self.setState({
                            purchaseAlert: res5.statusText
                          });
                        }
                      }
                    });
                  } else {
                    if (res.status !== 20) {
                      self.setState({
                        purchaseAlert: res.statusText
                      })
                    }
                  }
                })
              } else {
                self.props.showModal('Thank you for your purchase!', welcomeMessage, welcomeCallback, welcomeConfirmMessage);
                window.setTimeout(() => {
                  self.props.history.push('/complete-profile');
                  self.props.hideModal();
                }, 15000)
              }
            } else {
              if (res3.status != 20) {
                console.log('Error: ' + res3.statusText)
              }
            }
          })

        });
      } else {
        if (res.status !== 20) {
          self.setState({
            purchaseAlert: res.statusText + '. Please re-enter your billing information and try purchasing again.',
          });
        }
      }
    })
  }
  isShippingStateValid() {
    return this.state.shippingState !== 'NY' && this.state.shippingState !== 'NJ' && this.state.shippingState !== 'RI';
  }
  isContactInfoCompleted() {
    return this.state.guestId !== '';
  }
  isShippingInfoCompleted() {
     return this.isShippingStateValid() && this.state.shippingFirstName !== '' && this.state.shippingLastName !== '' && this.state.shippingAddress1 !== '' && this.state.shippingCity !== '' && this.state.shippingState !== '' && this.state.shippingCountry !== '' && this.state.shippingZip !== '';
  }
  isBillingInfoCompleted() {
    return this.state.stripeBillingMethod !== '';
  }
  isTermsInfoCompleted() {
    return this.state.isResidenceConfirmed && this.state.isAgeConfirmed && this.state.isTermsConfirmed;
  }
  isPasswordInfoCompleted() {
    if (this.state.password1 === '' && this.state.password2 === '') {
      return true;
    } else {

      if (this.state.password1 !== this.state.password2) {
        return false;
      }

      if (!utils.validatePassword(this.state.password1)) {
        return false;
      }
    }
    return true;
  }
  componentDidMount() {
    this.focusOn('info');
  }
  render(){
    var self = this;
    return (
      <div>
        <section className="white-bg green-text checkout-hero">
          <div className="section-image object-cover">
            <img className="lazyautosizes lazyloaded" data-sizes="auto" alt="Woman on stairs, holding railing and smiling towards the camera" data-src="/img/medium/checkout-hero.jpg" data-srcset="/img/small/checkout-hero.jpg 480w, /img/medium/checkout-hero.jpg 720w, /img/large/checkout-hero.jpg 1440w, /img/fullscreen/checkout-hero.jpg 2880w" sizes="1554px" srcSet="/img/small/checkout-hero.jpg 480w, /img/medium/checkout-hero.jpg 720w, /img/large/checkout-hero.jpg 1440w, /img/fullscreen/checkout-hero.jpg 2880w" src="/img/medium/checkout-hero.jpg" />
          </div>
          <div className="wrap over-image white-text">
            <h2 className="widont balance-text">Checkout</h2>
          </div>
        </section>

        <section className="white-bg green-text fullscreen checkout-section">
          <div className="section-image object-cover">
            <img className="lazyautosizes lazyloaded" data-sizes="auto" alt="Woman on stairs, holding railing and smiling towards the camera" data-src="/img/medium/checkout-hero.jpg" data-srcset="/img/small/checkout-hero.jpg 480w, /img/medium/checkout-hero.jpg 720w, /img/large/checkout-hero.jpg 1440w, /img/fullscreen/checkout-hero.jpg 2880w" sizes="1554px" srcSet="/img/small/checkout-hero.jpg 480w, /img/medium/checkout-hero.jpg 720w, /img/large/checkout-hero.jpg 1440w, /img/fullscreen/checkout-hero.jpg 2880w" src="/img/medium/checkout-hero.jpg" />
          </div>
          <div className="wrap split-wrap">
            <ul className="accordion">
              <li className="card tab">
                <input type="checkbox" id='chck1' checked={this.state.stage == 'info'} onClick={(evt) => this.changeStage(evt, 'info')} readOnly />
                <label className="tab-label" htmlFor='chck1' id="tab-info">
                  <h4>1. Contact Information</h4>
                </label>
                <article className="tab-content">
                  <div className="split">
                    <div className="split-text">
                      <p className="widont">We will email you a receipt.</p>
                      <p className="widont">In case an order processing or delivery issue occurs, someone from our team will need to reach you by phone.</p>
                    </div>
                    <div className="split-text">
                      <form id="guest-checkout-info-form" onSubmit={this.onContactInfoSubmit}>
                        <label className="input-wrap">
                          <input type="email" name="email" id="checkout-email" className={this.state.email !== '' ? 'has-value' : ''} value={this.state.email} onChange={this.onEmailChange} required/>
                          <span>Email address</span>
                        </label>
                        <label className="input-wrap">
                          <input type="text" name="phone" id="checkout-phone" className={this.state.phone !== '' ? 'has-value' : ''} value={this.state.phone} onChange={this.onPhoneChange} required />
                          <span>Phone number</span>
                        </label>

                        <fieldset className="align-center">
                          {ReactHtmlParser(this.state.infoAlert)}
                        </fieldset>

                        <fieldset className="button-wrap">
                          <input type="submit" className="button" id="checkout-info-submit" value="Continue to Shipping"/>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </article>
              </li>
              <li className="card tab">
                <input type="checkbox" id='chck2' checked={this.state.stage == 'shipping'} onClick={(evt) => this.changeStage(evt, 'shipping')} readOnly />
                <label className="tab-label" htmlFor='chck2' id="tab-shipping">
                  <h4>2. Shipping</h4>
                </label>
                <article className="tab-content">
                  <div className="split">
                    <div className="split-text">
                      <h4 className="widont balance-text">Shipping Address</h4>
                      {!this.isContactInfoCompleted() && <p className="widont warning">Please complete the previous section prior to filling out this form.</p>}
                    </div>
                    <div className="split-text">
                      <form id="guest-checkout-shipping-address-form" onSubmit={this.onShippingAddressSubmit}>
                        <div className="split">
                          <div className="split-text">
                            <label className="input-wrap">
                              <input type="text" name="shipping-first-name" id="checkout-shipping-first-name" className={this.state.shippingFirstName !== '' ? 'has-value' : ''} value={this.state.shippingFirstName} onChange={this.onShippingFirstNameChange} required disabled={!this.isContactInfoCompleted()} />
                              <span>First name</span>
                            </label>
                          </div>
                          <div className="split-text">
                            <label className="input-wrap">
                              <input type="text" name="shipping-last-name" id="checkout-shipping-last-name" className={this.state.shippingLastName !== '' ? 'has-value' : ''} value={this.state.shippingLastName} onChange={this.onShippingLastNameChange} required disabled={!this.isContactInfoCompleted()} />
                              <span>Last name</span>
                            </label>
                          </div>
                        </div>

                        <label className="input-wrap">
                          <input type="text" name="shipping-address1" id="checkout-shipping-address1" className={this.state.shippingAddress1 !== '' ? 'has-value' : ''} value={this.state.shippingAddress1} onChange={this.onShippingAddress1Change} required disabled={!this.isContactInfoCompleted()} />
                          <span>Address1</span>
                        </label>

                        <label className="input-wrap">
                          <input type="text" name="shipping-address2" id="checkout-shipping-address2" className={this.state.shippingAddress2 !== '' ? 'has-value' : ''} value={this.state.shippingAddress2} onChange={this.onShippingAddress2Change} disabled={!this.isContactInfoCompleted()} />
                          <span>Address2</span>
                        </label>

                        <div className="split">
                          <div className="split-text">
                            <label className="input-wrap">
                              <input type="text" name="shipping-city" id="checkout-shipping-city" className={this.state.shippingCity !== '' ? 'has-value' : ''} value={this.state.shippingCity} onChange={this.onShippingCityChange} required disabled={!this.isContactInfoCompleted()} />
                              <span>City</span>
                            </label>
                          </div>
                          <div className="split-text">
                            <label className="input-wrap">
                              <select id="shipping-state" className={this.state.shippingState !== '' ? 'has-value' : ''} value={this.state.shippingState} onChange={this.onShippingStateChange} disabled={!this.isContactInfoCompleted()}>
                                <option value=""></option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NM">New Mexico</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                              <span>State</span>
                            </label>
                            <fieldset>
                              {!this.isShippingStateValid() && <p className="warning">We currently cannot ship to NY, NJ, or RI. Visit our <a href="https://www.adyn.com/faq" target="_blank">FAQ</a> to find out why. When we begin expanding our supported states, we'll reach out to you by email.</p>}

                            </fieldset>
                          </div>
                        </div>

                        <div className="split">
                          <div className="split-text">
                            <label className="input-wrap">
                              <input type="text" name="shipping-zip" id="checkout-shipping-zip" className={this.state.shippingZip !== '' ? 'has-value' : ''} value={this.state.shippingZip} onChange={this.onZipChange} required disabled={!this.isContactInfoCompleted()} />
                              <span>Zip code</span>
                            </label>
                            <fieldset className="align-center w-100">
                              {this.state.zipAlert !== '' && <div className="alert info">{this.state.zipAlert}</div>}
                            </fieldset>
                          </div>
                          <div className="split-text">
                            <label className="input-wrap">
                              <select id="shipping-country" className={this.state.shippingCountry !== '' ? 'has-value' : ''} value={this.state.shippingCountry} onChange={this.onShippingCountryChange} disabled={!this.isContactInfoCompleted()}>
                                <option value="US">United States</option>
                              </select>
                              <span>Country</span>
                            </label>
                          </div>
                        </div>

                        <label className="input-wrap">
                          <input type="text" name="shipping-delivery-instructions" id="checkout-shipping-delivery-instructions" className="full" className={this.state.shippingDeliveryInstructions !== '' ? 'has-value' : ''} value={this.state.shippingDeliveryInstructions} onChange={this.onShippingDeliveryInstructionsChange} rows="5" disabled={!this.isContactInfoCompleted()} />
                          <span>Shipping delivery instructions</span>
                        </label>

                        <fieldset className="align-center">
                          {this.state.shippingAlert !== '' && <div className="alert info">{this.state.shippingAlert}</div>}
                        </fieldset>
                        <fieldset className="button-wrap">
                          <input type="submit" className="button" id="info-submit" value="Continue to Billing" disabled={!this.isContactInfoCompleted()}/>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </article>
              </li>
              <li className="card tab">
                <input type="checkbox" id='chck3' checked={this.state.stage == 'billing'} onClick={(evt) => this.changeStage(evt, 'billing')} readOnly />
                <label className="tab-label" htmlFor='chck3' id="tab-billing">
                  <h4>3. Billing</h4>
                </label>
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({stripe, elements}) => (
                      <StripeBillingForm
                        firstName={self.state.shippingFirstName}
                        lastName={self.state.shippingLastName}
                        address1={self.state.shippingAddress1}
                        address2={self.state.shippingAddress2}
                        city={self.state.shippingCity}
                        state={self.state.shippingState}
                        zip={self.state.shippingZip}
                        country={self.state.shippingCountry}
                        disabled={!self.isContactInfoCompleted() || !self.isShippingInfoCompleted()}
                        stripe={stripe}
                        elements={elements}
                        guestId={self.state.guestId}
                        onSubmit={self.onBillingSubmit}
                        onCouponFetched={self.onCouponFetched}
                      />
                    )}
                  </ElementsConsumer>
                </Elements>
              </li>
              <li className="card tab">
                <input type="checkbox" id='chck4' checked={this.state.stage == 'review'} onClick={(evt) => this.changeStage(evt, 'review')} readOnly />
                <label className="tab-label" htmlFor='chck4' id="tab-review">
                  <h4>4. Order Review</h4>
                </label>
                <article className="tab-content">
                  <form id="checkout-purchase-form" onSubmit={this.onPurchaseSubmit}>
                    <article className="tab-content">
                      <div className="split">
                        <div className="split-text">
                          <h4 className="widont balance-text">Agree to Terms</h4>
                          {(!this.isContactInfoCompleted() || !this.isShippingInfoCompleted() || !this.isBillingInfoCompleted()) && <p className="warning">Please complete the previous sections with valid entries prior to filling out this form.</p>}
                        </div>
                        <div className="split-text">
                          <label className="input-wrap">
                            <input
                              type="checkbox"
                              name="age"
                              id="checkout-age"
                              onChange={this.onAgeConfirmedChange}
                              disabled={!this.isContactInfoCompleted() || !this.isShippingInfoCompleted()} />
                            I am 18 years of age or older
                          </label>
                          <label className="input-wrap">
                            <input
                              type="checkbox"
                              name="terms"
                              onChange={this.onTermsConfirmedChange}
                              disabled={!this.isContactInfoCompleted() || !this.isShippingInfoCompleted()} />
                            I have read and agree to the adyn <a href="https://www.adyn.com/terms-of-service" target="_blank">terms of service</a> and <a href="https://www.iubenda.com/privacy-policy/94836304" target="_blank">privacy policy</a>
                          </label>
                        </div>
                      </div>
                    </article>
                    <article className="tab-content">
                      <div className="split">
                        <div className="split-text">
                          <h4 className="widont balance-text">Create Password*</h4>
                          <p>*<i>Optional</i>. Create a password to access your my.adyn.com profile where you can:</p>
                          <ul id="incentives">
                            <li>View the status of orders</li>
                            <li>Receive secure lab results directly through your dashboard</li>
                            <li>Manage shipping and billing information</li>
                            <li>Receive specialized support</li>
                          </ul>
                        </div>
                        <div className="split-text">
                          <label className="input-wrap">
                            <input type="password" name="password1" className={this.state.password1 !== '' ? 'has-value' : ''} value={this.state.password1} onChange={this.onPassword1Change} disabled={this.state.Purchased}/>
                            <span>Password</span>
                          </label>
                          <label className="input-wrap">
                            <input type="password" name="password2" className={this.state.password2 !== '' ? 'has-value' : ''} value={this.state.password2} onChange={this.onPassword2Change} disabled={this.state.Purchased}/>
                            <span>Confirm password</span>
                          </label>
                          <fieldset className="button-wrap align-center">
                            {this.state.passwordAlert !== '' && <div className="alert danger">{ReactHtmlParser(this.state.passwordAlert)}</div>}
                          </fieldset>
                        </div>
                      </div>
                    </article>
                    <article className="tab-content">
                      <div className="split">
                        <div className="split-text">
                          <h4>Review Order</h4>
                        </div>
                        <div className="split-text">
                          <table className="receipt">
                            <tbody>
                              <tr>
                                <td className="note">Please review your order and hit "Confirm" to finalize your purchase.</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>The Birth Control Test</td>
                                <td style={{textAlign: 'right'}}>$325</td>
                              </tr>
                              {this.state.percentOff > 0 &&
                                <tr>
                                  <td>Discount:</td>
                                  <td style={{textAlign: 'right'}}>{'-' + (325 * this.state.percentOff / 100)}</td>
                                </tr>
                              }
                              <tr style={{borderTop: '1px solid #000'}}>
                                <td>Total:</td>
                                <td className="total">
                                  <b>{'$' + (325 - (325 * this.state.percentOff / 100))}</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <fieldset className="button-wrap align-center">
                            {this.state.purchaseAlert !== '' && <div className="alert info">{this.state.purchaseAlert}</div>}
                          </fieldset>
                          <fieldset className="button-wrap">
                            <input type="submit" className="button" value="Confirm" disabled={this.state.isPurchased || !this.isContactInfoCompleted() || !this.isShippingInfoCompleted() || (!this.isBillingInfoCompleted() && this.state.couponCode.length !== 8) || !this.isTermsInfoCompleted() }/>
                          </fieldset>
                        </div>
                      </div>
                    </article>
                  </form>
                </article>
              </li>
            </ul>
          </div>

        </section>
      </div>
    )
  }
}

Checkout.propTypes = {
  user: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  showModal: PropTypes.func,
  onUpdateUser: PropTypes.func,
  location: PropTypes.object,
  shippingAddresses: PropTypes.array,
  onConsent: PropTypes.func,
  hideModal: PropTypes.func
}
